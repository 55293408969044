import React, { useEffect, useState } from 'react';
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    Link,
    useNavigate,
} from 'react-router-dom';
import PuffLoader from 'react-spinners/PuffLoader';
import { useAuthDataContext } from './contexts/user-auth-provider';
import SignIn from './components/signIn/Auth';
import CodeVerify from './components/signIn/CodeVerify';
import CreateDocumentPage from './pages/create-document/CreateDocument';
import DocumentTypes from './pages/document-types/DocumentTypes';
import DocumentTypeCreate from './pages/document-types-create/DocumentTypeCreate';
import DocumentDetailsPage from './pages/document-details/DocumentDetails';
import FileDetailsPage from './pages/file-details/FileDetails';
import DashboardPage from './pages/dashboard/Dashboard';
import Search from './pages/search/Search';
import Settings from './pages/settings/Settings';
import TaskDetailsPage from './pages/task-details/TaskDetails';
import Documentation from './pages/documentation/Documentation';
import ReleaseNotes from './pages/release-notes/ReleaseNotes';
import CONSTANTS from './constants';
import { setLocalStorage } from './utils/local-storage';
import { useGAPageView } from './hooks/useGoogleAnalytics';
import DocumentExportPage from './pages/document-export/DocumentExport';
import CreateDocinDcoModal from './components/new-doc-main/CreateDocInDcoModal';
import AdminDashboard from './pages/admin-dashboard/AdminDashboard';
import ContractsDashboard from './pages/contracts/ContractsDashboard';
import ContractsPage from './pages/contracts/ContractsPage';
import NDAForm from './pages/nda/ndaForm';
import AdminDocTypeTablePage from './pages/admin/AdminDocTypeTablePage';
import AdminDocTablePage from './pages/admin/AdminDocTablePage';
import ListAllTasksPage from './pages/list-all-tasks/ListAllTasksPage';
import ReportsPage from './pages/reports/ReportsPage';
import ReportsListPage from './pages/reports/ReportsListPage';
import ReportDetailSummary from './components/reports/ReportDetailSummary';
import AdminDelegations from './pages/admin-delegations/AdminDelegations';
import ActiveSessions from './components/session/ActiveSessions';
import { extendSession } from './API/session';

interface PrivateProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.FC<any>;
}

const NotFound: React.FC = () => (
    <div className="not-found ">
        <div className="circle">
            <h1>404</h1>
        </div>
        <h3>Page Not Found</h3>
        <p>
            Go to <Link to="/dashboard">Dashboard</Link>
        </p>
    </div>
);

const Unauthorized: React.FC = () => (
    <div className="unauthorized">
        <div className="circle">
            <h1>401</h1>
        </div>
        <h3>Unauthorized</h3>
        <p>Please contact Admin</p>
    </div>
);

const PrivateRoute = ({ component: Component, ...rest }: PrivateProps) => {
    const { user, tokenValid, isLoading } = useAuthDataContext();
    if (!isLoading) {
        if (user.id && user.id !== -1) {
            return <Component {...rest} />
        }

        if (tokenValid) {
            return <Navigate to="/404" />;
        }

        return <Navigate to="/signin" />;
    }
    return (
        <div className="puff-loader">
            <PuffLoader color={CONSTANTS.BRAND_COLOR} size={100} />
        </div>
    );
};

const AdminRoute = ({ component: Component, ...rest }: PrivateProps) => {
    const { user, tokenValid, isLoading } = useAuthDataContext();
    if (!isLoading) {
        if (user.id && user.id !== -1 && (user.isAdmin || user.isDcoAdmin)) {
            return <Component {...rest} />;
        }

        if (tokenValid) {
            return <Navigate to="/401" />;
        }

        return <Navigate to="/signin" />;
    }
    return (
        <div className="puff-loader">
            <PuffLoader color={CONSTANTS.BRAND_COLOR} size={100} />
        </div>
    );
};

const ClmRoute = ({ component: Component, ...rest }: PrivateProps) => {
    const { user, tokenValid, isLoading } = useAuthDataContext();
    if (!isLoading) {
        if (user.id && user.id !== -1 && (user.isAdmin || user.isClmAdmin)) {
            return <Component {...rest} />;
        }

        if (tokenValid) {
            return <Navigate to="/401" />;
        }

        return <Navigate to="/signin" />;
    }
    return (
        <div className="puff-loader">
            <PuffLoader color={CONSTANTS.BRAND_COLOR} size={100} />
        </div>
    );
};

// eslint-disable-next-line max-lines-per-function
const Router1: React.FC = () => {
    const location = useLocation();
    const { triggerPageView } = useGAPageView();
    const [warningTimeoutId, setWarningTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [logoutTimeoutId, setLogoutTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [showExpiryModel, handleExpiryModel] = useState(false);
    const { user, onLogout } = useAuthDataContext();
    const isAllowed = localStorage.getItem('X-Puppeteer') === '1234567';

    const navigate = useNavigate();
    if (!['/signin', '/oauth/callback'].includes(location.pathname)) {
        setLocalStorage('path', location.pathname + location.search);
    }

    useEffect(() => {
        triggerPageView(location); // This will trigger whenever the location changes
    }, [location, triggerPageView]);

    const closeModal = () => {
        handleExpiryModel(false);
    };

    const calculateExpiry = async (): Promise<number> => {
        let sessionExpiry;
        const lastSuccessfulApi = localStorage.getItem('lastSuccessfulApiCall');
        if (!lastSuccessfulApi) {
            sessionExpiry = Math.floor(Date.now() / 1000) + Number(CONSTANTS.SESSION_MAXAGE) / 1000;
        } else {
            const lastSuccessfulApiInSeconds = Math.floor(new Date(lastSuccessfulApi).getTime() / 1000);
            sessionExpiry = lastSuccessfulApiInSeconds + Number(CONSTANTS.SESSION_MAXAGE) / 1000;
        }
        sessionExpiry -= 5;
        return sessionExpiry;
    };
    const checkSessionExpiry = async () => {
        const sessionExpiry = await calculateExpiry();
        const currentTime = Math.floor(Date.now() / 1000);
        if (sessionExpiry <= currentTime) {
            await onLogout();
            return;
        }
        if (warningTimeoutId) clearTimeout(warningTimeoutId);
        if (logoutTimeoutId) clearTimeout(logoutTimeoutId);
        const timeRemaining = sessionExpiry - currentTime;
        const newWarningTimeout = setTimeout(async () => {
            const updatedExpiry = await calculateExpiry();
            const updatedCurrentTime = Math.floor(Date.now() / 1000);
            if (updatedExpiry - updatedCurrentTime > Number(CONSTANTS.SESSION_WARNING_SECONDS)) {
                checkSessionExpiry();
                return;
            }
            handleExpiryModel(true);
        }, Math.max(0, (timeRemaining - Number(CONSTANTS.SESSION_WARNING_SECONDS)) * 1000));

        const newLogoutTimeout = setTimeout(async () => {
            const updatedExpiry = await calculateExpiry();
            const updatedCurrentTime = Math.floor(Date.now() / 1000);
            if (updatedExpiry <= updatedCurrentTime) {
                await onLogout();
                return;
            }
            checkSessionExpiry();

        }, Math.max(0, timeRemaining * 1000));

        setWarningTimeoutId(newWarningTimeout);
        setLogoutTimeoutId(newLogoutTimeout);
    };
    useEffect(() => { checkSessionExpiry(); }, []);
    const handleSessionExtension = async () => {
        await extendSession();
        await checkSessionExpiry();
        handleExpiryModel(false);
    };


    return (
        <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/oauth/callback" element={<CodeVerify />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="/401" element={<Unauthorized />} />
            <Route path="/document-details/export" element={isAllowed ? <DocumentExportPage /> : <Unauthorized />} />
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<PrivateRoute component={DashboardPage} />} />
            <Route path="/create-document" element={<PrivateRoute component={CreateDocumentPage} />} />
            <Route path="/active-sessions" element={<PrivateRoute component={ActiveSessions} />} />
            <Route path="/create-document-in-dco" element={<PrivateRoute component={CreateDocinDcoModal} />} />
            <Route path="/document-types" element={<AdminRoute component={DocumentTypes} />} />
            <Route path="/document-details" element={<PrivateRoute component={DocumentDetailsPage} />} />
            <Route path="/file-details" element={<PrivateRoute component={FileDetailsPage} />} />
            {/* <Route path="/document-details/export" element={<PrivateRoute component={DocumentExportPage} />} /> */}
            <Route path="/task-details" element={<PrivateRoute component={TaskDetailsPage} />} />
            <Route path="/document-type/create" element={<AdminRoute component={DocumentTypeCreate} />} />
            <Route path="/search" element={<PrivateRoute component={Search} />} />
            <Route path="/documentation" element={<PrivateRoute component={Documentation} />} />
            <Route path="/release-notes" element={<PrivateRoute component={ReleaseNotes} />} />
            <Route path="/settings" element={<PrivateRoute component={Settings} />} />
            <Route path="/adm/dashboard" element={(user.isTrapAdmin && !user.isAdmin && !user.isDcoAdmin) ? <PrivateRoute component={ListAllTasksPage} /> : <PrivateRoute component={AdminDashboard} />} />
            <Route path="/adm/document-types-config" element={<PrivateRoute component={AdminDocTypeTablePage} />} />
            <Route path="/adm/documents" element={<PrivateRoute component={AdminDocTablePage} />} />
            <Route path="/adm/tasks" element={<PrivateRoute component={ListAllTasksPage} />} />
            <Route path="/adm/delegations" element={<PrivateRoute component={AdminDelegations} />} />
            <Route path="/clm/dashboard" element={<ClmRoute component={ContractsDashboard} />} />
            <Route path="/clm/contracts" element={<ClmRoute component={ContractsPage} />} />
            <Route path="/clm/nda-form" element={<PrivateRoute component={NDAForm} />} />
            <Route path="/reportList" element={<PrivateRoute component={ReportsListPage} />} />
            <Route path="/reportList/report" element={<PrivateRoute component={ReportsPage} />} />
            <Route path="/reportList/detail-summary" element={<PrivateRoute component={ReportDetailSummary} />} />
        </Routes>
    );
};

const Router: React.FC = () => {
    const validRoutes = [
        '/',
        '/dashboard',
        '/signin',
        '/oauth/callback',
        '/404',
        '/401',
        '/create-document',
        '/document-types',
        '/document-details',
        '/file-details',
        '/task-details',
        '/document-type/create',
        '/search',
        '/settings',
        '/documentation',
        '/release-notes',
        '/document-details/export',
        '/create-document-in-dco',
        '/adm/dashboard',
        '/adm/document-types-config',
        '/adm/documents',
        '/adm/tasks',
        '/adm/delegations',
        '/clm/nda-form',
        '/clm/dashboard',
        '/clm/contracts',
        '/reportList',
        '/reportList/report',
        '/reportList/detail-summary',
        '/active-sessions',
    ];
    const location = useLocation();
    if (!validRoutes.includes(location.pathname)) {
        return <Navigate to="/404" />;
    }
    return <Router1 />;
};

export default Router;
