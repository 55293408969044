import { ISession } from '../interfaces/session';
import { deleteRequest, getRequest } from '../utils/api-request';

const extendSession = async (): Promise<boolean> => {
    try {
        const response = await getRequest(`auth/extend-session`);
        if (response?.apiStatus !== 'SUCCESS') {
            throw new Error(`Error: ${response?.apiStatus}`);
        }
        return true;
    } catch (error) {
        return false;
    }
};
const destroySessionById = async (sessionId: string): Promise<boolean> => {
    try {
        const response = await deleteRequest(`auth/sessions/${sessionId}`);
        return response?.apiStatus === 'SUCCESS'; // Return true if session deleted successfully
    } catch (error) {
        return false;
    }
};
const fetchActiveSessions = async (userId: number): Promise<ISession[]> => {
    try {
        const response = await getRequest(`auth/sessions/active/${userId}`);
        if (response?.apiStatus === 'SUCCESS') {
            return response.response;
        }
        return [];

    } catch (error) {
        return [];
    }
};
export { destroySessionById, fetchActiveSessions, extendSession };
