/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-lines-per-function */
import qs from 'qs';
import {
    IAPIResponseStatus,
    IDocStatus,
    IExternalApprover,
    IOptionValue,
    IOwnerChange,
    IReviewers,
    IStringObject,
    IUserType
} from '../interfaces';
import { IAttachmentConfig } from '../interfaces/attachment';
import {
    IApprovalDocStage,
    IDocApprovers,
    IDocReferenceInfo,
    IDocVersionInfo,
    IDocumentActionTypes,
    IDocumentActivity,
    IDocumentHistory,
    IDocumentInfo,
    IField,
    IFieldsData,
    IRefDocsData,
    IVersionNumberInfo,
} from '../interfaces/document';
import { IDocTypeConfig, IDocVersionObject, IDocumentTypeInfo } from '../interfaces/document-type';
import { getDocVersion, getUserFullName } from '../utils';
import { getRequest, getRequestWithResponse, postRequest, putRequest } from '../utils/api-request';
import { getFavoriteDocIds } from './dashboard';
import { getActiveDelegators } from './delegation';
import { userSearch } from './users';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const editToCcFields = async (documentId: number, data: any) => {
    try {
        // console.log(`%%%%%%%%%%% ${JSON.stringify(data)} %%%%%%%%%%%`)
        const res = await postRequest(`documents/${documentId}/edit-to-cc`, {
            toList: data.toList,
            ccList: data.ccList,
        });

        if (res) return { apiStatus: res.apiStatus };
        return undefined;
    } catch (error) {
        // console.log(error);
        return undefined;
    }
};

const getAllEmailData = async (
    documentId: number,
    documentVersionId: number,
    fieldName: string,
): Promise<{ apiStatus: IAPIResponseStatus; emails: [] } | undefined> => {
    try {
        const res = await getRequest(
            `documents/${documentId}/versions/${documentVersionId}/get-all-email-data/${fieldName}`,
        );
        if (res?.apiStatus === 'SUCCESS') {
            return {
                apiStatus: res.apiStatus,
                emails: res.response,
            };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const createDocument = async (
    documentTypeId: number,
    data: any,
): Promise<
    | { apiStatus: IAPIResponseStatus; docId: number; docVersionId: number; errorMessage?: string; docNumber?: string }
    | undefined
> => {
    try {
        const res = await postRequest(`documents/`, {
            documentTypeId,
            data,
        });
        // console.log(`**--after doc creation api res: ${JSON.stringify(res)}`);
        if (res?.apiStatus === 'SUCCESS') {
            const { response, apiStatus } = res;
            return {
                apiStatus,
                docId: response.documentId,
                docVersionId: response.id,
                docNumber: response?.document?.documentNumber
            };
        }
        if (res?.apiStatus === 'FAILURE') {
            return {
                apiStatus: res.apiStatus,
                docId: 0,
                docVersionId: 0,
                errorMessage: res.response.message,
            };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const createNDA = async (
    approver: any,
    externalApprover: any,
    data: any,
): Promise<
    | { apiStatus: IAPIResponseStatus; docId: number; docVersionId: number; errorMessage?: string }
    | undefined
> => {
    try {
        data.externalApprovers.push(externalApprover);
        const approverData = {
            employeeId: String(approver[0].value),
            isEditable: true,
            state: 'NEW',
            reminders: [],
        };
        data.approver.push(approverData);
        const res = await postRequest(`documents/nda`, data);
        if (res?.apiStatus === 'SUCCESS') {
            const { response, apiStatus } = res;
            return {
                apiStatus,
                docId: response.documentId,
                docVersionId: response.id,
            };
        }
        if (res?.apiStatus === 'FAILURE') {
            return {
                apiStatus: res.apiStatus,
                docId: 0,
                docVersionId: 0,
                errorMessage: res.response.message,
            };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const makeDocumentObsolete = async (
    documents: IOwnerChange[],
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
    }
    | undefined
> => {
    try {
        const docs = documents.map((document) => document.documentId);
        const reqBody: any = {
            docs,
        };
        // console.log(reqBody);
        const res = await postRequest(`documents/make-obsolete`, reqBody);
        if (res?.apiStatus === 'SUCCESS') {
            const { response, apiStatus } = res;
            return {
                apiStatus,
            };
        }
        if (res?.apiStatus === 'FAILURE') {
            return {
                apiStatus: res.apiStatus,
            };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const getDocument = async (
    documentId: number,
    allowReviseByAnyone: boolean,
    isDocumentDetails?: boolean,
    allowDCO?: boolean,
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        documentInfo: {
            activeDocumentVersion: IOptionValue;
            versions: IOptionValue[];
            createdAt: number;
            state: IDocStatus;
            approvedDocumentVersion: number;
            activeDocumentVersionOwnerId: number;
            activeDocumentVersionCheckedInFlag: boolean;
        };
        documentVersions?: any;
    }
    | undefined
> => {
    try {
        const docResponse = await getRequest(
            `documents/${documentId}?allowReviseByAnyone=${allowReviseByAnyone}&isDocumentDetails=${isDocumentDetails === true
            }`,
        );
        if (docResponse?.apiStatus === 'SUCCESS') {
            const versions: IOptionValue[] = [];
            const documentVersions = docResponse.response.documentVersion;
            for (const version of documentVersions) {
                versions.push({
                    value: version.id,
                    label: getDocVersion(
                        version.majorVersionNumber,
                        version.minorVersionNumber,
                        version.versionState === 'RERELEASE' || version.versionState === 'OBSOLETE'
                            ? version.versionState
                            : '',
                        version.state,
                        allowDCO,
                    ),
                });
            }
            return {
                apiStatus: docResponse.apiStatus,
                documentInfo: {
                    activeDocumentVersion: {
                        value: docResponse.response.activeVersionId,
                        label: getDocVersion(
                            docResponse.response.activeDocumentVersion.majorVersionNumber,
                            docResponse.response.activeDocumentVersion.minorVersionNumber,
                            docResponse.response.activeDocumentVersion.versionState,
                            docResponse.response.activeDocumentVersion.state,
                            allowDCO,
                        ),
                    },
                    versions,
                    createdAt: docResponse.response.createdAt,
                    state: docResponse.response.state,
                    approvedDocumentVersion: docResponse.response.approvedVersionId,
                    activeDocumentVersionOwnerId:
                        docResponse.response.activeDocumentVersion.versionOwnerId,
                    activeDocumentVersionCheckedInFlag:
                        docResponse.response.activeDocumentVersion?.checkedIn,
                },
                documentVersions
            };
        }
        if (docResponse?.apiStatus) {
            return {
                apiStatus: docResponse.apiStatus,
                documentInfo: {
                    versions: [],
                    activeDocumentVersion: { label: '', value: '' },
                    createdAt: 0,
                    state: 'NEW',
                    approvedDocumentVersion: 0,
                    activeDocumentVersionOwnerId: 0,
                    activeDocumentVersionCheckedInFlag: false,
                },
            };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const getDocumentsDetails = async (
    documentIds: (string | number)[],
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        data: IDocReferenceInfo[];
    }
    | undefined
> => {
    try {
        const queryString = documentIds.map((id) => `ids=${id}`).join('&');
        const responseData = await getRequest(`documents/documents-details?${queryString}`);

        if (responseData?.apiStatus === 'SUCCESS') {
            const { response } = await responseData;

            const docRefDataFull: IDocReferenceInfo[] = response.map((data: any) => {
                const activeDocVersionData: IDocVersionInfo = {
                    id: data?.activeDocumentVersion?.id,
                    majorVersionNumber: data?.activeDocumentVersion?.majorVersionNumber,
                    minorVersionNumber: data?.activeDocumentVersion?.minorVersionNumber,
                    data: data?.activeDocumentVersion?.data,
                    state: data?.activeDocumentVersion?.state,
                    documentId: data?.activeDocumentVersion?.documentId,
                    documentTypeVersionId: data?.activeDocumentVersion?.documentTypeVersionId,
                    versionOwnerId: data?.activeDocumentVersion?.versionOwnerId,
                    documentAttachment: data?.activeDocumentVersion?.documentAttachment,
                    documentTypeVersion: data?.activeDocumentVersion?.documentTypeVersion,
                    versionState: data?.activeDocumentVersion?.versionState,
                    checkedIn: data?.activeDocumentVersion?.checkedIn,
                    approvalDeadline: data?.activeDocumentVersion?.approvalDeadline,
                };

                const approvedDocVersionData: IDocVersionInfo = {
                    id: data?.approvedDocumentVersion?.id,
                    majorVersionNumber: data?.approvedDocumentVersion?.majorVersionNumber,
                    minorVersionNumber: data?.approvedDocumentVersion?.minorVersionNumber,
                    data: data?.approvedDocumentVersion?.data,
                    state: data?.approvedDocumentVersion?.state,
                    documentId: data?.approvedDocumentVersion?.documentId,
                    documentTypeVersionId: data?.approvedDocumentVersion?.documentTypeVersionId,
                    versionOwnerId: data?.approvedDocumentVersion?.versionOwnerId,
                    documentAttachment: data?.approvedDocumentVersion?.documentAttachment,
                    documentTypeVersion: data?.approvedDocumentVersion?.documentTypeVersion,
                    versionState: data?.approvedDocumentVersion?.versionState,
                    checkedIn: data?.approvedDocumentVersion?.checkedIn,
                    approvalDeadline: data?.approvedDocumentVersion?.approvalDeadline,
                };

                const docRefData: IDocReferenceInfo = {
                    id: data?.id,
                    documentNumber: data?.documentNumber,
                    title: data?.title,
                    documentLock: data?.documentLock,
                    state: data?.state,
                    activeVersionId: data?.activeVersionId,
                    approvedVersionId: data?.approvedVersionId,
                    ownerId: data?.ownerId,
                    activeDocumentVersion: activeDocVersionData,
                    approvedDocumentVersion: data?.approvedDocumentVersion
                        ? approvedDocVersionData
                        : null,
                    parentDCOInfo: data?.parentDCOInfo,
                };
                return docRefData;
            });

            return {
                apiStatus: responseData.apiStatus,
                data: docRefDataFull,
            };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const getDocumentsReferenceDetails = async (
    documentIds: (string | number)[],

    // documentVersionId: number,
    // employeeId: number,
): Promise<
    | {
        //   accessibleByCurrentUser: boolean;
        apiStatus: IAPIResponseStatus;
        data: [];
    }
    | undefined
> => {
    try {
        // const responseData = await getRequest(
        //     `documents/${documentId}/versions/${documentVersionId}`,
        // );

        const queryString = documentIds.map((id) => `ids=${id}`).join('&');
        const responseData = await getRequest(`documents/documents-details?${queryString}`);

        if (responseData?.apiStatus === 'SUCCESS') {
            const { response } = await responseData;

            const data = response;

            return {
                apiStatus: responseData.apiStatus,
                data,
            };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const getDocumentRelationsInfo = async (
    docVersionId: number,
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        data: [];
    }
    | undefined
> => {
    try {
        const docResponse = await getRequest(`documents/relations-info/${docVersionId}`);
        if (docResponse?.apiStatus === 'SUCCESS') {
            return {
                apiStatus: docResponse.apiStatus,
                data: docResponse.response,
            };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const toggleDocumentLock = async (
    documentLock: boolean,
    documentId: number,
    DCOdocId: number
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        data: [];
    }
    | undefined
> => {
    try {
        const body = {
            documentLock,
            DCOdocId
        };

        const responseData = await putRequest(`documents/toggle-document-lock/${documentId}`, body);
        if (responseData?.apiStatus === 'SUCCESS') {
            const { response } = await responseData;
            const data = response;
            return {
                apiStatus: responseData.apiStatus,
                data,
            };
        }

        return undefined;
    } catch (err) {
        return undefined;
    }
};

const updateApprovlStatusForTravelForm = async (
    email: string,
    documentId: number,
    body: any,
): Promise<{
    department: string;
} | undefined> => {
    try {
        const responseData = await postRequest(
            `documents/travel-form/update-approval-state/${email}/${documentId}`, body
        );
        if (responseData?.apiStatus === 'SUCCESS') {
            return {
                department: responseData.response.department,
            }
        }
        return undefined;
    } catch (error) {
        return undefined;
    }
}
const updateApprovalStatusForPRBForm = async (email: string, documentId: number): Promise<{ approvalFlow: any } | undefined> => {
    try {
        const res = await postRequest(`documents/prb-form/approval-status-object/${email}/${documentId}`, {});
        if (res?.apiStatus === 'SUCCESS') {
            return {
                approvalFlow: res.response.approvalFlow,
            }
        }
        return undefined;
    } catch (error) {
        return undefined;
    }
}
const updateApprovalStatusForLPNF = async (email: string, documentId: number): Promise<{ approvalFlow: any } | undefined> => {
    try {
        const res = await postRequest(`documents/lpnf-form/approval-status-object/${email}/${documentId}`, {});
        if (res?.apiStatus === 'SUCCESS') {
            return {
                approvalFlow: res.response.approvalFlow,
            }
        }
        return undefined;
    } catch (error) {
        return undefined;
    }
}

const checkInDocument = async (
    checkedIn: boolean,
    documentVersionId: number,
    relationArr?: any[]
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        data: [];
    }
    | undefined
> => {
    try {
        const firstRelationId = relationArr && relationArr.length > 0 ? relationArr[0].id : undefined;
        const body = {
            checkedIn,
            firstRelationId
        };

        const responseData = await putRequest(`documents/check-in/${documentVersionId}`, body);
        if (responseData?.apiStatus === 'SUCCESS') {
            const { response } = await responseData;
            const data = response;
            return {
                apiStatus: responseData.apiStatus,
                data,
            };
        }

        return undefined;
    } catch (err) {
        return undefined;
    }
};

const getAllDocVersionIds = async (
    documentIds: number[],
): Promise<{ documentVersionIds: number[] } | undefined> => {
    try {
        const queryString = documentIds.map((id) => `ids=${id}`).join('&');
        const responseData = await getRequest(
            `documents/documents-activeVersionIds?${queryString}`,
        );
        const documentVersionIds: number[] = responseData?.response;
        return { documentVersionIds };
    } catch (err) {
        return undefined;
    }
};

const getDocumentVersion = async (
    documentId: number,
    documentVersionId: number,
    employeeId: number,
    allowReviseByAnyone: boolean,
    isDocumentDetails?: boolean,
): Promise<
    | {
        accessibleByCurrentUser: boolean;
        docInfo: IDocumentInfo;
        docTypeInfo: IDocumentTypeInfo;
        fields: IField[];
        data: { [key: string]: unknown };
        approvers: IApprovalDocStage[];
        externalApprovers: IExternalApprover[];
        reviewers: IReviewers[];
        activeApprovalStatusId: number;
        externalApprovalId: number;
        userType: IUserType;
        attachmentConfig: IAttachmentConfig[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        schema: any;
        hideUsers: number[];
        collectComment: boolean;
        collectCommentRejection: boolean;
        collectCommentWithdraw: boolean;
        docTypeConfig: IDocTypeConfig;
        prefixes: [{ type: 'static' | 'dynamic'; value: string }];
        taskId: string;
        republishCount: number;
        checkInVersionOwner: any;
        versionOwner: any;
        versionNumberInfo: IVersionNumberInfo;
        documentTypeId: number;
        submittedOn: number;
        versionState: string;
        approvalDeadline: number;
        existingContract: boolean;
        checklist: { question: string; value: null | string }[] | null;
        disableApprovalFlow: boolean;
        updatedOn?: number;
        docVersionAttachment?: any;
    }
    | undefined
> => {
    try {
        const responseData = await getRequest(
            `documents/${documentId}/versions/${documentVersionId}?allowReviseByAnyone=${allowReviseByAnyone}&isDocumentDetails=${isDocumentDetails === true
            }`,
        );
        let userType: IUserType = 'OTHER';
        // Getting fav to check if document is marked as favorite
        let favoriteDocIds: number[] = [];
        const favoritesRes = await getFavoriteDocIds();
        if (favoritesRes?.apiStatus === 'SUCCESS') {
            favoriteDocIds = favoritesRes.documentIds;
        }

        if (responseData?.apiStatus === 'SUCCESS') {
            const { response } = await responseData;
            const docVersionAttachment = response?.documentAttachment;
            const schema = response?.documentTypeVersion.dataSchema;
            const { accessibleByCurrentUser } = response;

            // Multiplying with 1000 to make it compatible with DatePicker (Will add 00:00:00 to Date)
            let formatDate;
            let formatTillDate;
            if (
                response.effectiveFrom === null &&
                !response.documentTypeVersion.documentType.config.publishOnceApproved
            ) {
                formatDate = new Date();
            } else if (
                response.effectiveFrom !== null &&
                !response.documentTypeVersion.documentType.config.publishOnceApproved
            ) {
                formatDate = new Date(Number(response.effectiveFrom) * 1000);
            } else {
                formatDate = response.effectiveFrom;
            }
            if (
                response.effectiveTill === null &&
                !response.documentTypeVersion.documentType.config.publishOnceApproved
            ) {
                formatTillDate = new Date();
            } else if (
                response.effectiveTill !== null &&
                !response.documentTypeVersion.documentType.config.publishOnceApproved
            ) {
                formatTillDate = new Date(Number(response.effectiveTill) * 1000);
            } else {
                formatTillDate = response.effectiveTill;
            }
            const submittedOn = response?.submittedOn;
            const updatedOn = response?.updatedAt;
            const docInfo: IDocumentInfo = {
                docState: response?.document.state,
                approvedVersionId: response?.document?.approvedVersionId
                    ? response?.document?.approvedVersionId
                    : 0,
                title: response?.document?.title ? response?.document?.title : '',
                description: response?.document.description ? response?.document?.description : '',
                confidential: response?.document.confidential,
                author: {
                    id: response?.document.ownerId,
                    name: response?.document?.owner
                        ? getUserFullName(
                            response?.document?.owner.firstName,
                            response?.document?.owner.lastName,
                        )
                        : '',
                },
                itemCreator: {
                    id: response?.document?.itemCreatorId
                        ? response?.document.itemCreatorId
                        : response?.document.ownerId,
                    name: response?.document?.itemCreator
                        ? getUserFullName(
                            response?.document?.itemCreator.firstName,
                            response?.document?.itemCreator.lastName,
                        )
                        : '',
                },
                submitter: {
                    id: response?.submitter ? response?.submitter.id : 0,
                    name: response?.submitter
                        ? getUserFullName(
                            response?.submitter.firstName,
                            response?.submitter.lastName,
                        )
                        : '',
                },
                versionOwner: {
                    id: response?.versionOwnerId,
                    name: response?.versionOwner
                        ? getUserFullName(
                            response?.versionOwner.firstName,
                            response?.versionOwner.lastName,
                        )
                        : '',
                },
                render: response?.render,
                assignee: response.assignee,
                docNumber: response?.document.documentNumber,
                isFavorite: !!favoriteDocIds.includes(documentId),
                approvedOn: response?.approvedOn,
                effectiveFrom: formatDate,
                ...(response.effectivePeriod && {
                    effectivePeriod: Math.floor(response?.effectivePeriod / 604800),
                }),
                effectiveTill: formatTillDate,
                ...(response.sunsetPeriod &&
                    response?.documentTypeVersion?.documentType?.config.isEndDateAsked && {
                    sunsetPeriod: Math.floor(response?.sunsetPeriod / 86400),
                }),
                ...(response.sunsetPeriod &&
                    !response?.documentTypeVersion?.documentType?.config.isEndDateAsked && {
                    sunsetPeriod: Math.floor(response?.sunsetPeriod / 604800),
                }),
                activeDocumentState: response?.state,
                documentLock: response?.document.documentLock,
                isEverGreen: response?.everGreen,
                autoRenew: response?.autoRenew,
            };

            const defaultInitialVersionValue: IDocVersionObject = {
                majorVersionNumber: 1,
                minorVersionNumber: 0,
            };
            const docTypeInfo: IDocumentTypeInfo = {
                description: response?.documentTypeVersion.documentType.description,
                prefixCode: response?.documentTypeVersion.documentType.prefixCode,
                isConfidential: response?.documentTypeVersion?.documentType?.config?.isConfidential,
                status: 'PUBLISHED',
                title: response?.documentTypeVersion.documentType.name,
                version: '',
                allowExport: response?.documentTypeVersion?.documentType?.config?.allowExport
                    ? response.documentTypeVersion.documentType.config.allowExport
                    : false,
                isDCO: response?.documentTypeVersion?.documentType?.config?.isDCO
                    ? response.documentTypeVersion.documentType.config.isDCO
                    : false,
                fullWidthTitleDesc: response?.documentTypeVersion?.documentType?.config
                    ?.fullWidthTitleDesc
                    ? response.documentTypeVersion.documentType.config.fullWidthTitleDesc
                    : false,
                singlePageDocCreation: response?.documentTypeVersion?.documentType?.config
                    ?.singlePageDocCreation
                    ? response.documentTypeVersion.documentType.config.singlePageDocCreation
                    : false,
                restrictCommentForPublished: response?.documentTypeVersion?.documentType?.config
                    ?.restrictCommentForPublished
                    ? response.documentTypeVersion.documentType.config.restrictCommentForPublished
                    : false,
                showCommentsAfterSubmitDate: response?.documentTypeVersion?.documentType?.config
                    ?.showCommentsAfterSubmitDate
                    ? response.documentTypeVersion.documentType.config.showCommentsAfterSubmitDate
                    : false,
                allowDCO: response?.documentTypeVersion?.documentType?.config?.allowDCO
                    ? response.documentTypeVersion.documentType.config.allowDCO
                    : false,
                allowObsolete: response?.documentTypeVersion?.documentType?.config?.allowObsolete
                    ? response.documentTypeVersion.documentType.config.allowObsolete
                    : false,
                allowRerelease: response?.documentTypeVersion?.documentType?.config?.allowRerelease
                    ? response.documentTypeVersion.documentType.config.allowRerelease
                    : false,
                allowReviseByAnyone: response?.documentTypeVersion?.documentType?.config
                    ?.allowReviseByAnyone
                    ? response.documentTypeVersion.documentType.config.allowReviseByAnyone
                    : false,
                allowViewingInProcessDocByAnyone: response?.documentTypeVersion?.documentType
                    ?.config?.allowViewingInProcessDocByAnyone
                    ? response.documentTypeVersion.documentType.config
                        .allowViewingInProcessDocByAnyone
                    : false,
                allowCustomVersioning: response?.documentTypeVersion?.documentType?.config
                    ?.allowCustomVersioning
                    ? response.documentTypeVersion.documentType.config.allowCustomVersioning
                    : false,
                defaultInitialVersion: response?.documentType?.config?.defaultInitialVersion
                    ? response?.documentType?.config?.defaultInitialVersion
                    : defaultInitialVersionValue,
                blockRevise: response?.documentTypeVersion?.documentType?.config?.blockRevise
                    ? response.documentTypeVersion.documentType.config.blockRevise
                    : false,
                anyoneCanEditDraft: response?.documentTypeVersion?.documentType?.config?.anyoneCanEditDraft
                    ? response.documentTypeVersion.documentType.config.anyoneCanEditDraft
                    : false,
                isContract: response?.documentTypeVersion?.documentType?.config?.isContract
                    ? response.documentTypeVersion.documentType.config.isContract
                    : false,
                allowTemplateGeneration: response?.documentTypeVersion?.documentType?.config
                    ?.allowTemplateGeneration
                    ? response.documentTypeVersion.documentType.config.allowTemplateGeneration
                    : false,
                enableApprovalDeadline: response?.documentTypeVersion?.documentType?.config?.enableApprovalDeadline
                    ? response?.documentTypeVersion?.documentType?.config?.enableApprovalDeadline
                    : false,
                defaultApprovalDeadline: response?.documentTypeVersion?.documentType?.config?.defaultApprovalDeadline
                    ? response?.documentTypeVersion?.documentType?.config?.defaultApprovalDeadline
                    : 5,
                hideEventTimeline: response?.documentTypeVersion?.documentType?.config?.hideEventTimeline
                    ? response?.documentTypeVersion?.documentType?.config?.hideEventTimeline
                    : false,
                storeAndShowActivity: response?.documentTypeVersion?.documentType?.config?.storeAndShowActivity
                    ? response?.documentTypeVersion?.documentType?.config?.storeAndShowActivity
                    : false,
                detailsSectionTitle: response?.documentTypeVersion?.documentType?.config
                    ?.detailsSectionTitle
                    ? response?.documentTypeVersion?.documentType?.config?.detailsSectionTitle
                    : undefined,
                attachmentSectionTitle: response?.documentTypeVersion?.documentType?.config
                    ?.attachmentSectionTitle
                    ? response?.documentTypeVersion?.documentType?.config?.attachmentSectionTitle
                    : undefined,
                approversSectionTitle: response?.documentTypeVersion?.documentType?.config
                    ?.approversSectionTitle
                    ? response?.documentTypeVersion?.documentType?.config?.approversSectionTitle
                    : undefined,
                approverTimeStamp: response?.documentTypeVersion?.documentType?.config
                    ?.approverTimeStamp
                    ? response?.documentTypeVersion?.documentType?.config?.approverTimeStamp
                    : undefined,
                isFileType: response?.documentTypeVersion?.documentType?.config?.isFileType
                    ? response?.documentTypeVersion?.documentType?.config?.isFileType
                    : false,
                allowFileTypeAttachment: response?.documentTypeVersion?.documentType?.config?.allowFileTypeAttachment
                    ? response?.documentTypeVersion?.documentType?.config?.allowFileTypeAttachment
                    : false,
            };
            const documentTypeId = response?.documentTypeVersion.documentType.id;
            const prefixes =
                response?.documentTypeVersion?.documentType?.documentNumberStrategy.prefixes;
            const taskId = response?.taskId;
            const { data } = response;
            const fields: IField[] = [];
            const approvers: IApprovalDocStage[] = [];
            const reviewers: IReviewers[] = [];
            let activeApprovalStatusId = 0;
            // TODO: this can be taken from user id
            const fieldsData = response?.documentTypeVersion?.fieldSet?.fields;
            const attachmentData =
                response?.documentTypeVersion?.attachmentConfiguration?.attachments;
            const approversData = response?.activeApprovalStatus?.data?.stages?.value;
            const disableApprovalFlow = response?.activeApprovalStatus?.data?.disableApprovalFlow
                ? response?.activeApprovalStatus?.data?.disableApprovalFlow
                : false;
            const reviewersData = response?.activeApprovalStatus?.data?.reviewers;
            const existingContract =
                response?.activeApprovalStatus?.data?.existingContract ?? false;
            const collectComment = response?.documentTypeVersion?.documentType?.config
                ?.collectComment
                ? response.documentTypeVersion.documentType.config.collectComment
                : false;
            const collectCommentRejection = response?.documentTypeVersion?.documentType?.config
                ?.collectCommentRejection
                ? response.documentTypeVersion.documentType.config.collectCommentRejection
                : false;
            const collectCommentWithdraw = response?.documentTypeVersion?.documentType?.config
                ?.collectCommentWithdraw
                ? response.documentTypeVersion.documentType.config.collectCommentWithdraw
                : false;
            const docTypeConfig = response?.documentTypeVersion?.documentType?.config;
            const { republishCount } = response;
            if (fields) {
                // eslint-disable-next-line no-restricted-syntax
                for (const f of fieldsData) {
                    let selectOption: IOptionValue[] = [];
                    if (f.selectType && f.selectType === 'static') {
                        selectOption = f.staticOptionValues.data.map((a: string) => ({
                            value: a,
                            label: a,
                        }));
                    }

                    // if (f.selectType && f.selectType === 'dynamic') {
                    //     // eslint-disable-next-line no-await-in-loop
                    //     const dynamicData = await getDynamicValues(f.id);
                    //     selectOption = dynamicData ? dynamicData.dynamicData : [];
                    // }
                    fields.push({
                        id: f.id,
                        name: f.name,
                        helperText: f.helperText ? f.helperText : '',
                        maxLength: f.maxLength ? f.maxLength : 0,
                        displayName: f.displayName,
                        step: f.step ? f.step : 0.01,
                        dataType: {
                            label: f.dataType.name,
                            value: f.dataType.id,
                            uiControls: f.dataType.uiControls,
                        },
                        uiControl: f.uiControl,
                        mandatory: f.isMandatory,
                        dynamicOptionValues: f.dynamicOptionValue,
                        staticOptionValues: f.staticOptionValue,
                        selectType: f.selectType,
                        selectOption,
                        markupText: f.markupText ? f.markupText : '',
                        docTypeOptionValues: f.docTypeOptionValues,
                        docTypeFieldsOptionValues: f.docTypeFieldsOptionValues,
                        isLargeData: f.isLargeData ? f.isLargeData : false,
                        readOnly: f.readOnly ? f.readOnly : false,
                    });
                }
            }

            const hideUsers: number[] = [];

            if (reviewersData?.length > 0) {
                const userIds: string[] = [];
                for (const reviewer of reviewersData) {
                    hideUsers.push(parseInt(reviewer.value, 10));
                    reviewers.push({
                        reviewerName: '',
                        reviewerId: reviewer.value,
                        role: '',
                        roleId: 0,
                        state: reviewer.state,
                    });
                    userIds.push(reviewer.value);
                }
                const usersList = await userSearch(undefined, undefined, userIds, true);
                if (usersList?.apiStatus === 'SUCCESS') {
                    for (const info of reviewers) {
                        if (info.reviewerId) {
                            const [u] = usersList.users.filter(
                                (user) => user.value.toString() === info.reviewerId.toString(),
                            );
                            if (u) {
                                info.reviewerName = u.label;
                                info.role = u.role;
                                info.roleId = u.roleId;
                            }
                        }
                    }
                } else {
                    // TODO : need to check about what to do if API fails
                }
            }

            const externalApprovalId = responseData.response?.contractExternalApprovers?.id
                ? responseData.response?.contractExternalApprovers?.id
                : null;
            const externalApprovers = responseData.response?.contractExternalApprovers?.approvers
                ? responseData.response?.contractExternalApprovers?.approvers
                : [];
            let { checklist } = response;
            if (docTypeConfig.allowDCO && checklist === null) {
                checklist = [
                    {
                        question:
                            'The document title in the file matches the document record in DMS.',
                        value: null,
                    },
                    {
                        question:
                            'The document number and revision are on the file. First page and in the header.',
                        value: null,
                    },
                    {
                        question:
                            'Changes have been accepted and comments deleted, change tracking is turned off.',
                        value: null,
                    },
                    { question: 'The revision history table has been completed.', value: null },
                    {
                        question:
                            'The approval table in the file has correct approvers as per the PLCP PMG-00001 and no ex-employees are listed as approvers.',
                        value: null,
                    },
                    { question: 'The copyright date is current.', value: null },
                ];
            }
            if (docTypeConfig.isDCO && checklist === null) {
                checklist = [
                    {
                        question: 'Redline Document attached?',
                        value: null,
                    },
                    {
                        question:
                            'Does the revision number on the DCO match the revision number on the file?',
                        value: null,
                    },
                ];
            }

            if (approversData) {
                const approvalStageTitleData =
                    response?.documentTypeVersion?.approvalWorkflowDefinition?.stages;
                let currStageIdx = 0;
                activeApprovalStatusId = response.activeApprovalStatus.id;
                for (const approverStage of approversData) {
                    const approversInfo: IDocApprovers[] = [];
                    let userIds: string[] = [];
                    for (const approverInfo of approverStage.approvers) {
                        if (approverInfo.employeeId) {
                            userIds.push(approverInfo.employeeId);
                            hideUsers.push(parseInt(approverInfo.employeeId, 10));

                            if (Number(approverInfo.employeeId) === employeeId) {
                                userType = 'APPROVER';
                            }
                        }
                        const loopAdHoc = (
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            adHOCList: any,
                        ): { adHoc1: IDocApprovers[]; userIds1: string[] } => {
                            let adHoc1: IDocApprovers[] = [];
                            let userIds1: string[] = [];
                            for (const user of adHOCList) {
                                adHoc1.push({
                                    employee: {
                                        jobTitle: '',
                                        label: '',
                                        role: '',
                                        roleId: user.roleId,
                                        value: user.employeeId,
                                        activeUser: user.activeUser,
                                    },
                                    isEditable: user.isEditable,
                                    reminders: user.reminders,
                                    roleId: user.roleId,
                                    state: user.state,
                                    updatedAt: user.updatedAt
                                        ? new Date(user.updatedAt)
                                        : undefined,
                                    reason: user.reason ? user.reason : undefined,
                                });
                                userIds1.push(user.employeeId);
                                hideUsers.push(parseInt(user.employeeId, 10));
                                if (user.adHOC) {
                                    const { userIds1: userIds2, adHoc1: adHoc2 } = loopAdHoc(
                                        user.adHOC,
                                    );
                                    adHoc1 = [...adHoc1, ...adHoc2];
                                    userIds1 = [...userIds1, ...userIds2];
                                }
                            }
                            return { adHoc1, userIds1 };
                        };

                        let adHoc: IDocApprovers[] = [];
                        if (approverInfo.adHOC) {
                            const { userIds1, adHoc1 } = loopAdHoc(approverInfo.adHOC);
                            adHoc = [...adHoc, ...adHoc1];
                            userIds = [...userIds, ...userIds1];
                        }
                        approversInfo.push({
                            isEditable: approverInfo.isEditable,
                            employee: {
                                jobTitle: '',
                                label: '',
                                role: '',
                                roleId: approverInfo.roleId,
                                value: approverInfo.employeeId,
                                activeUser: approverInfo.activeUser,
                            },
                            ...(approverInfo.groupId && { groupId: approverInfo.groupId }),
                            ...(approverInfo.individualGroupApproverId && {
                                individualGroupApproverId: approverInfo.individualGroupApproverId,
                            }),
                            reminders: approverInfo.reminders,
                            roleId: approverInfo.roleId,
                            state: approverInfo.state,
                            customApproval: approverInfo.customApproval,
                            multiApproval: approverInfo.multiApproval,
                            ...(approverInfo.groupCanEdit && {
                                groupCanEdit: approverInfo.groupCanEdit,
                            }),
                            updatedAt: approverInfo.updatedAt
                                ? new Date(approverInfo.updatedAt)
                                : undefined,
                            reason: approverInfo.reason ? approverInfo.reason : undefined,
                            adHoc,
                            docuSignTriggered: approverInfo.docuSignTriggered
                                ? approverInfo.docuSignTriggered
                                : false,
                        });
                    }
                    // eslint-disable-next-line no-await-in-loop
                    const usersList = await userSearch(undefined, undefined, userIds, true);
                    if (usersList?.apiStatus === 'SUCCESS') {
                        for (const info of approversInfo) {
                            if (info.employee.value) {
                                const [u] = usersList.users.filter(
                                    (user) =>
                                        user.value.toString() === info.employee.value.toString(),
                                );
                                if (u) {
                                    info.employee = u;
                                }
                            }
                            if (info.adHoc) {
                                for (const a of info.adHoc) {
                                    const [adHoc] = usersList.users.filter(
                                        (user) =>
                                            user.value.toString() === a.employee.value.toString(),
                                    );
                                    if (adHoc) {
                                        a.employee = adHoc;
                                    }
                                }
                            }
                        }
                        approvers.push({
                            type: approverStage.type,
                            scopeType: approverStage.scopeType,
                            reminders: approverStage.reminders?.length
                                ? approverStage.reminders
                                : [response?.approvalDeadline],
                            roleList: approverStage.roleList,
                            individualGroupApproverId: approverStage.individualGroupApproverId,
                            approvers: approversInfo,
                            exempt: approverStage.exempt,
                            customApproval: approverStage.customApproval,
                            multiApproval: approverStage.multiApproval,
                            approvalStageTitle:
                                approvalStageTitleData[currStageIdx]?.scope?.config
                                    ?.approvalStageTitle,
                            mandatory:
                                approvalStageTitleData[currStageIdx]?.scope?.config?.mandatory,
                            updatedAt: approverStage?.updatedAt,
                            canApproversEdit: approverStage?.canApproversEdit
                                ? approverStage?.canApproversEdit
                                : false,
                            disable: approverStage?.disable ? approverStage?.disable : false,
                            approversDeleted: approverStage?.approversDeleted ? approverStage?.approversDeleted : false
                        });
                    } else {
                        // TODO : need to check about what to do if API fails
                    }
                    currStageIdx += 1;
                }
            }

            const {
                versionOwner,
                checkInVersionOwner,
                minorVersionNumber,
                majorVersionNumber,
                versionState,
                approvalDeadline,
            } = response;
            // console.log(`***resss: ${JSON.stringify(response)}, ^^^^^ approvalDealine: ${approvalDeadline}`);
            const versionNumberInfo = {
                minorVersionNumber,
                majorVersionNumber,
            };
            const attachmentConfig: IAttachmentConfig[] = attachmentData || [];
            if (
                response.document.ownerId === employeeId ||
                docTypeInfo.allowReviseByAnyone ||
                versionOwner?.id === employeeId
            ) {
                userType = 'CREATOR';
            }

            if (!docTypeConfig.isDCO) {
                hideUsers.push(parseInt(response.document.ownerId, 10));
            }

            return {
                docInfo,
                fields,
                data,
                approvers,
                reviewers,
                externalApprovers,
                activeApprovalStatusId,
                externalApprovalId,
                userType,
                attachmentConfig,
                schema,
                hideUsers,
                collectComment,
                collectCommentRejection,
                collectCommentWithdraw,
                docTypeConfig,
                docTypeInfo,
                accessibleByCurrentUser,
                prefixes,
                taskId,
                republishCount,
                checkInVersionOwner,
                versionOwner,
                versionNumberInfo,
                documentTypeId,
                submittedOn,
                versionState,
                approvalDeadline,
                existingContract,
                checklist,
                disableApprovalFlow,
                updatedOn,
                docVersionAttachment
            };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const exportPDF = async (documentId: number): Promise<string> => {
    try {
        const res = await getRequest(`documents/export/${documentId}`);
        if (res?.apiStatus === 'SUCCESS') {
            const FileName: string = res.response;
            return FileName;
        }
        return '';
    } catch (err) {
        return '';
    }
};

const createEventForEditDocument = async (documentId: number, userId: number) => {
    try {
        const res = await postRequest('documents/create-event-for-edit-document', {
            documentId,
            userId,
        });
    } catch (error) {
        console.log(error);
    }
}

const downloadPDF = async (fileName: string) => {
    try {
        const res = await getRequestWithResponse(`documents/download/${fileName}`, {
            responseType: 'blob',
        });
        if (res?.apiStatus === 'SUCCESS') {
            if (typeof res.response === 'object' && res.response instanceof Blob) {
                const blob = res.response;
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.setAttribute('download', fileName || '');
                document.body.appendChild(link);
                link.click();
                return res.apiStatus;
            }
            // Handle the case where the response is not a Blob
            console.error('Unexpected response type:', typeof res.response);
            return 'FAILURE';
        }
        if (res?.apiStatus === 'FAILURE') {
            return res.apiStatus;
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const getTaskDetails = async (
    taskId: string,
    userId: number,
): Promise<
    | {
        docInfo: IDocumentInfo;
        docTypeInfo: IDocumentTypeInfo;
        fieldsData: IFieldsData[];
        documentId: number;
        userType: IUserType;
        isActive: boolean;
        activeAssigner: boolean;
        delegatorInfo: IOptionValue;
        approvers: IApprovalDocStage[];
        reviewers: IReviewers[];
        documentVersion: IOptionValue;
        externalApprovers: IExternalApprover[];
        approverStatus: string;
        accessibleByCurrentUser: boolean;
        createdAt: number;
        versionOwner: any;
        prefixCode: string;
    }
    | undefined
> => {
    try {
        const res = await getRequest(`tasks/${taskId}`);
        if (res?.apiStatus === 'SUCCESS') {
            const response = res.response[0];
            const fieldsData: IFieldsData[] = [];
            // eslint-disable-next-line no-restricted-syntax
            const keys = Object.keys(response.document.data);
            for (const key of keys) {
                fieldsData.push({
                    name: key,
                    value: response.document.data[key],
                });
            }

            const isActive = !response.endTime;
            const { accessibleByCurrentUser } = response;
            let delegatorInfo: IOptionValue = {
                value: 0,
                label: '',
            };

            const documentId = response.document?.id ? response.document?.id : 0;
            let userType: IUserType = 'OTHER';

            if (response.name === 'Approval') userType = 'APPROVER';
            else if (response.name === 'Reviewer') userType = 'REVIEWER';

            const { assigneeId } = response;

            let activeAssigner = false;
            if (parseInt(assigneeId, 10) === userId) {
                activeAssigner = true;
            } else {
                const activeDelegators = await getActiveDelegators(userId);
                if (activeDelegators) {
                    const [activeDelegator] = activeDelegators.filter(
                        (a) => a.value === parseInt(assigneeId, 10),
                    );

                    if (activeDelegator) {
                        activeAssigner = true;
                        delegatorInfo = activeDelegator;
                    }
                }
            }
            // Multiplying with 1000 to make it compatible with DatePicker (Will add 00:00:00 to Date)
            let formatDate;
            let formatTillDate;
            if (response.documentVersionObj.effectiveFrom !== null) {
                formatDate = new Date(Number(response.documentVersionObj.effectiveFrom) * 1000);
            } else {
                formatDate = response.effectiveFrom;
            }

            if (
                response.documentVersionObj.effectiveTill === null &&
                !response.documentVersionObj.documentTypeVersion.documentType.config
                    .publishOnceApproved
            ) {
                formatTillDate = new Date();
            } else if (
                response.documentVersionObj.effectiveTill !== null &&
                !response.documentVersionObj.documentTypeVersion.documentType.config
                    .publishOnceApproved
            ) {
                formatTillDate = new Date(Number(response.documentVersionObj.effectiveTill) * 1000);
            } else {
                formatTillDate = response.documentVersionObj.effectiveTill;
            }
            const approvalStageTitleData = response.documentVersionObj?.documentTypeVersion?.approvalWorkflowDefinition?.stages;
            const docInfo: IDocumentInfo = {
                docState: 'UNDER_REVIEW',
                approvedVersionId: 0,
                title: response.document.title ? response.document.title : '',
                description: response.document.description ? response.document.description : '',
                confidential: response.document.isConfidential
                    ? response.document.isConfidential
                    : false,
                author: {
                    id: response.document.owner.id ? response.document.owner.id : 0,
                    name: response.document.owner
                        ? getUserFullName(
                            response.document.owner.firstName,
                            response.document.owner.lastName,
                        )
                        : '',
                },
                itemCreator: {
                    id: response.document.itemCreator?.id ? response.document.itemCreator.id : 0,
                    name: response.document?.itemCreator
                        ? getUserFullName(
                            response.document.itemCreator.firstName,
                            response.document.itemCreator.lastName,
                        )
                        : '',
                },
                submitter: {
                    id: response?.submitter ? response?.submitter.id : 0,
                    name: response?.submitter
                        ? getUserFullName(
                            response?.submitter.firstName,
                            response?.submitter.lastName,
                        )
                        : '',
                },
                versionOwner: {
                    id: response?.documentVersionObj?.versionOwnerId,
                    name: response?.documentVersionObj?.versionOwner
                        ? getUserFullName(
                            response?.documentVersionObj?.versionOwner.firstName,
                            response?.documentVersionObj?.versionOwner.lastName,
                        )
                        : '',
                },
                assignee: response?.assignee,
                render: response.render,
                docNumber: response.document.documentNumber ? response.document.documentNumber : '',
                isFavorite: false,
                approvedOn: response?.approvedOn,
                effectiveFrom: formatDate,
                ...(response.documentVersionObj.effectivePeriod && {
                    effectivePeriod: Math.floor(
                        response?.documentVersionObj.effectivePeriod / 604800,
                    ),
                }),
                effectiveTill: formatTillDate,
                ...(response.documentVersionObj.sunsetPeriod &&
                    response?.documentVersionObj?.documentTypeVersion?.documentType?.config
                        .isEndDateAsked && {
                    sunsetPeriod: Math.floor(response?.documentVersionObj.sunsetPeriod / 86400),
                }),
                ...(response.documentVersionObj.sunsetPeriod &&
                    !response?.documentVersionObj?.documentTypeVersion?.documentType?.config
                        .isEndDateAsked && {
                    sunsetPeriod: Math.floor(
                        response?.documentVersionObj.sunsetPeriod / 604800,
                    ),
                }),
                isEverGreen: response?.documentVersionObj.everGreen,
                autoRenew: response?.documentVersionObj.autoRenew,
                activeDocumentState: response?.documentVersionObj?.state,
            };

            // const documentVersions = docResponse.response.documentVersion;
            // for (const version of documentVersions) {
            //     versions.push({
            //         value: version.id,
            //         label: getDocVersion(version.majorVersionNumber, version.minorVersionNumber, version.versionState === "RERELEASE" || version.versionState === "OBSOLETE" ? version.versionState: ""),
            const documentVersion: IOptionValue = {
                value: response.documentVersionObj.id,
                label: getDocVersion(
                    response.document.majorVersionNumber,
                    response.document.minorVersionNumber,
                    response.documentVersionObj.versionState,
                ),
            };
            const defaultInitialVersionValue: IDocVersionObject = {
                majorVersionNumber: 1,
                minorVersionNumber: 0,
            };
            const approverStatus = response.approverState;
            const docTypeInfo: IDocumentTypeInfo = {
                description: '',
                isConfidential: false,
                status: 'PUBLISHED',
                title: response.document?.documentType?.name
                    ? response.document?.documentType?.name
                    : '',
                version: '',
                allowExport:
                    response.documentVersionObj.documentTypeVersion.documentType.config.allowExport,
                isDCO: response.documentVersionObj.documentTypeVersion.documentType.config.isDCO
                    ? response.documentVersionObj.documentTypeVersion.documentType.config.isDCO
                    : false,
                allowTemplateGeneration: response?.documentTypeVersion?.documentType?.config
                    ?.allowTemplateGeneration
                    ? response.documentTypeVersion.documentType.config.allowTemplateGeneration
                    : false,
                fullWidthTitleDesc: response.documentVersionObj.documentTypeVersion.documentType
                    .config.fullWidthTitleDesc
                    ? response.documentVersionObj.documentTypeVersion.documentType.config
                        .fullWidthTitleDesc
                    : false,
                singlePageDocCreation: response.documentVersionObj.documentTypeVersion.documentType
                    .config.singlePageDocCreation
                    ? response.documentVersionObj.documentTypeVersion.documentType.config
                        .singlePageDocCreation
                    : false,
                restrictCommentForPublished: response.documentVersionObj.documentTypeVersion
                    .documentType.config.restrictCommentForPublished
                    ? response.documentVersionObj.documentTypeVersion.documentType.config
                        .restrictCommentForPublished
                    : false,
                showCommentsAfterSubmitDate: response.documentVersionObj.documentTypeVersion
                    .documentType.config.showCommentsAfterSubmitDate
                    ? response.documentVersionObj.documentTypeVersion.documentType.config
                        .showCommentsAfterSubmitDate
                    : false,
                allowDCO: false,
                allowObsolete: false,
                allowRerelease: false,
                allowReviseByAnyone: false,
                allowViewingInProcessDocByAnyone: false,
                allowCustomVersioning: false,
                defaultInitialVersion: defaultInitialVersionValue,
                blockRevise: false,
                anyoneCanEditDraft: false,
                prefixCode: response.document?.documentType?.code
                    ? response.document?.documentType?.code
                    : '',
                enableApprovalDeadline: response?.documentVersionObj?.documentTypeVersion
                    ?.documentType?.config?.enableApprovalDeadline
                    ? response?.documentVersionObj?.documentTypeVersion?.documentType?.config
                        ?.enableApprovalDeadline
                    : false,
                defaultApprovalDeadline: response?.documentVersionObj?.documentTypeVersion
                    ?.documentType?.config?.defaultApprovalDeadline
                    ? response?.documentVersionObj?.documentTypeVersion?.documentType?.config
                        ?.defaultApprovalDeadline
                    : 5,
                isContract: response.documentVersionObj?.documentTypeVersion?.documentType?.config
                    ?.isContract
                    ? response.documentVersionObj?.documentTypeVersion?.documentType?.config
                        ?.isContract
                    : false,
                isFileType: response?.documentVersionObj?.documentTypeVersion?.documentType?.config?.isFileType
                    ? response?.documentVersionObj?.documentTypeVersion?.documentType?.config?.isFileType
                    : false,
                allowFileTypeAttachment: response?.documentVersionObj?.documentTypeVersion?.documentType?.config?.allowFileTypeAttachment
                    ? response?.documentVersionObj?.documentTypeVersion?.documentType?.config?.allowFileTypeAttachment
                    : false,
                hideEventTimeline: response?.documentType?.config?.hideEventTimeline
                    ? response?.documentType?.config?.hideEventTimeline
                    : false,
                storeAndShowActivity: response?.documentType?.config?.storeAndShowActivity
                    ? response?.documentType?.config?.storeAndShowActivity
                    : false,
                detailsSectionTitle: response.documentVersionObj?.documentTypeVersion?.documentType?.config
                    ?.detailsSectionTitle
                    ? response.documentVersionObj?.documentTypeVersion?.documentType?.config
                        ?.detailsSectionTitle
                    : undefined,
                attachmentSectionTitle: response.documentVersionObj?.documentTypeVersion
                    ?.documentType?.config?.attachmentSectionTitle
                    ? response.documentVersionObj?.documentTypeVersion?.documentType?.config
                        ?.attachmentSectionTitle
                    : undefined,
                approversSectionTitle: response.documentVersionObj?.documentTypeVersion
                    ?.documentType?.config?.approversSectionTitle
                    ? response.documentVersionObj?.documentTypeVersion?.documentType?.config
                        ?.approversSectionTitle
                    : undefined,
                approverTimeStamp: response.documentVersionObj?.documentTypeVersion?.documentType
                    ?.config?.approverTimeStamp
                    ? response.documentVersionObj?.documentTypeVersion?.documentType?.config
                        ?.approverTimeStamp
                    : undefined,
            };
            const { prefixCode } = response.documentVersionObj.documentTypeVersion.documentType;
            const { createdAt } = response?.documentVersionObj;
            const approvers: IApprovalDocStage[] = [];
            const reviewers: IReviewers[] = [];
            const approversData =
                response?.documentVersionObj?.activeApprovalStatus?.data?.stages?.value;
            const reviewersData =
                response?.documentVersionObj?.activeApprovalStatus?.data?.reviewers;

            const hideUsers: number[] = [];
            const externalApprovers =
                response?.documentVersionObj?.contractExternalApprovers?.approvers;

            if (reviewersData?.length > 0) {
                const userIds: string[] = [];
                for (const reviewer of reviewersData) {
                    hideUsers.push(parseInt(reviewer.value, 10));
                    reviewers.push({
                        reviewerName: '',
                        reviewerId: reviewer.value,
                        role: '',
                        roleId: 0,
                        state: reviewer.state,
                    });
                    userIds.push(reviewer.value);
                }
                const usersList = await userSearch(undefined, undefined, userIds, true);
                if (usersList?.apiStatus === 'SUCCESS') {
                    for (const info of reviewers) {
                        if (info.reviewerId) {
                            const [u] = usersList.users.filter(
                                (user) => user.value.toString() === info.reviewerId.toString(),
                            );
                            if (u) {
                                info.reviewerName = u.label;
                                info.role = u.role;
                                info.roleId = u.roleId;
                            }
                        }
                    }
                } else {
                    // TODO : need to check about what to do if API fails
                }
            }

            if (approversData) {
                // let activeApprovalStatusId = response.activeApprovalStatus.id;
                let currStageIdx = 0;
                for (const approverStage of approversData) {
                    const approversInfo: IDocApprovers[] = [];
                    let userIds: string[] = [];
                    for (const approverInfo of approverStage.approvers) {
                        if (approverInfo.employeeId) {
                            userIds.push(approverInfo.employeeId);
                            hideUsers.push(parseInt(approverInfo.employeeId, 10));
                        }
                        const loopAdHoc = (
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            adHOCList: any,
                        ): { adHoc1: IDocApprovers[]; userIds1: string[] } => {
                            let adHoc1: IDocApprovers[] = [];
                            let userIds1: string[] = [];
                            for (const user of adHOCList) {
                                adHoc1.push({
                                    employee: {
                                        jobTitle: '',
                                        label: '',
                                        role: '',
                                        roleId: user.roleId,
                                        value: user.employeeId,
                                        activeUser: user.activeUser,
                                    },
                                    isEditable: user.isEditable,
                                    reminders: user.reminders,
                                    roleId: user.roleId,
                                    state: user.state,
                                    updatedAt: user.updatedAt
                                        ? new Date(user.updatedAt)
                                        : undefined,
                                    reason: user.reason ? user.reason : undefined,
                                });
                                userIds1.push(user.employeeId);
                                hideUsers.push(parseInt(user.employeeId, 10));
                                if (user.adHOC) {
                                    const { userIds1: userIds2, adHoc1: adHoc2 } = loopAdHoc(
                                        user.adHOC,
                                    );
                                    adHoc1 = [...adHoc1, ...adHoc2];
                                    userIds1 = [...userIds1, ...userIds2];
                                }
                            }
                            return { adHoc1, userIds1 };
                        };

                        let adHoc: IDocApprovers[] = [];
                        if (approverInfo.adHOC) {
                            const { userIds1, adHoc1 } = loopAdHoc(approverInfo.adHOC);
                            adHoc = [...adHoc, ...adHoc1];
                            userIds = [...userIds, ...userIds1];
                        }
                        approversInfo.push({
                            isEditable: approverInfo.isEditable,
                            groupCanEdit: approverInfo.groupCanEdit,
                            employee: {
                                jobTitle: '',
                                label: '',
                                role: '',
                                roleId: approverInfo.roleId,
                                value: approverInfo.employeeId,
                                activeUser: approverInfo.activeUser,
                            },
                            ...(approverInfo.groupId && { groupId: approverInfo.groupId }),
                            reminders: approverInfo.reminders,
                            roleId: approverInfo.roleId,
                            state: approverInfo.state,
                            updatedAt: approverInfo.updatedAt
                                ? new Date(approverInfo.updatedAt)
                                : undefined,
                            reason: approverInfo.reason ? approverInfo.reason : undefined,
                            adHoc,
                            docuSignTriggered: approverInfo.docuSignTriggered
                                ? approverInfo.docuSignTriggered
                                : false,
                        });
                    }
                    // eslint-disable-next-line no-await-in-loop
                    const usersList = await userSearch(undefined, undefined, userIds, true);
                    if (usersList?.apiStatus === 'SUCCESS') {
                        for (const info of approversInfo) {
                            if (info.employee.value) {
                                const [u] = usersList.users.filter(
                                    (user) =>
                                        user.value.toString() === info.employee.value.toString(),
                                );
                                if (u) {
                                    info.employee = u;
                                }
                            }
                            if (info.adHoc) {
                                for (const a of info.adHoc) {
                                    const [adHoc] = usersList.users.filter(
                                        (user) =>
                                            user.value.toString() === a.employee.value.toString(),
                                    );
                                    if (adHoc) {
                                        a.employee = adHoc;
                                    }
                                }
                            }
                        }
                        approvers.push({
                            type: approverStage.type,
                            scopeType: approverStage.scopeType,
                            reminders: approverStage.reminders,
                            roleList: approverStage.roleList,
                            approvers: approversInfo,
                            exempt: approverStage.exempt,
                            customApproval: approverStage.customApproval,
                            multiApproval: approverStage.multiApproval,
                            mandatory: approverStage.mandatory,
                            updatedAt: approverStage?.updatedAt,
                            approvalStageTitle:
                                approvalStageTitleData[currStageIdx]?.scope?.config
                                    ?.approvalStageTitle,
                            canApproversEdit: approverStage?.canApproversEdit
                                ? approverStage?.canApproversEdit
                                : false,
                            individualGroupApproverId: approverStage?.individualGroupApproverId,
                            disable: approverStage?.disable ? approverStage?.disable : false,
                            approversDeleted: approverStage?.approversDeleted ? approverStage?.approversDeleted : false
                        });
                    } else {
                        // TODO : need to check about what to do if API fails
                    }
                    currStageIdx += 1;
                }
            }

            const { versionOwner } = response;

            return {
                docInfo,
                documentId,
                fieldsData,
                userType,
                isActive,
                activeAssigner,
                docTypeInfo,
                delegatorInfo,
                approvers,
                externalApprovers,
                reviewers,
                documentVersion,
                approverStatus,
                accessibleByCurrentUser,
                createdAt,
                versionOwner,
                prefixCode,
            };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const getActiveTask = async (
    taskId: string,
): Promise<
    | {
        taskid: string;
    }
    | undefined
> => {
    try {
        const res = await getRequest(`tasks/get-active-task/${taskId}`);
        if (res?.apiStatus === 'SUCCESS') {
            const taskid = res.response.taskId;
            return {
                taskid,
            };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const updateDCOfields = async (
    DCOList: number[],
    NewVersionData: IDocReferenceInfo[] | undefined,
    docId: number,
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string } | undefined> => {
    try {
        const response = await putRequest(`documents/updateDocVersioninDCOs/${docId}`, {
            DCOList,
            NewVersionData,
        });
        if (response?.apiStatus === 'SUCCESS') {
            return { apiStatus: response.apiStatus, errorMessage: '' };
        }
        if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, errorMessage: '' };
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const updateDocumentVersionFieldsData = async (
    fieldsData: IFieldsData[],
    documentId: number,
    documentVersionId: number,
    IsAddToDCO?: boolean,
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string } | undefined> => {
    try {
        const data: {
            [key: string]: unknown;
        } = {};
        let flag = false;
        if (IsAddToDCO) {
            flag = IsAddToDCO;
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const fieldData of fieldsData) {
            if (Array.isArray(fieldData.value as any)) {
                data[fieldData.name] = (fieldData.value as any).map((v: { value: any }) => {
                    if (v.value) {
                        return v.value;
                    }
                    return v;
                });
            } else {
                data[fieldData.name] = fieldData.value;
            }
        }
        const response = await putRequest(
            `documents/${documentId}/versions/${documentVersionId}/updateFields/${flag}`,
            {
                data,
            },
        );
        if (response?.apiStatus === 'SUCCESS') {
            return { apiStatus: response.apiStatus, errorMessage: '' };
        }
        if (response?.apiStatus === 'FAILURE') {
            const values = JSON.parse(
                response.response.message.replace('Data validation failed ', ''),
            );
            let error = `Data Validation failed\n`;
            // eslint-disable-next-line no-restricted-syntax
            for (const value of values) {
                error += `${value.instancePath} ${value.message}\n`;
            }
            return { apiStatus: response.apiStatus, errorMessage: error };
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const updateAutoRenew = async (
    documentId: number,
    documentVersionId: number,
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string } | undefined> => {
    try {
        const response = await putRequest(
            `documents/${documentId}/versions/${documentVersionId}/updateAutoRenew`,
            {},
        );
        if (response?.apiStatus === 'SUCCESS') {
            return { apiStatus: response.apiStatus, errorMessage: '' };
        }
        if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, errorMessage: '' };
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const updateDocumentVersion = async (
    fieldsData: IFieldsData[],
    documentId: number,
    documentVersionId: number,
    activeApprovalStatusId: number,
    isEndDateAsked: boolean,
    reviewers: IReviewers[],
    approversData: IApprovalDocStage[],
    checklist: { question: string; value: null | string }[] | null,
    externalApprovalId: number,
    externalApprovers: IExternalApprover[],
    ownerId: number,
    allowReviseByAnyone: boolean,
    isEverGreen: boolean,
    autoRenew: boolean,
    activeVersionApprovalDeadline: number,
    existingContract: boolean,
    effectiveFromDate?: Date | undefined | number,
    effectiveTillDate?: Date | undefined | number,
    effectivePeriodTime?: number,
    sunsetPeriod?: number,
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string } | undefined> => {
    try {
        const activeApprovalStatus = {
            id: activeApprovalStatusId,
            data: {
                existingContract,
                ownerId,
                reviewers: reviewers.map((r) => ({
                    value: r.reviewerId.toString(),
                    state: r.state,
                })),

                stages: {
                    value: approversData.map(({ approvers, ...rest }) => ({
                        ...rest,
                        approvers: approvers.map(({ employee, adHoc, ...rest1 }) => ({
                            ...rest1,
                            employeeId: employee.value ? employee.value.toString() : null,
                            reminders: [activeVersionApprovalDeadline],
                            // eslint-disable-next-line @typescript-eslint/no-shadow
                            adHOC: adHoc?.map(({ employee, ...rest2 }) => ({
                                ...rest2,
                                employeeId: employee.value ? employee.value.toString() : null,
                                reminders: [activeVersionApprovalDeadline],
                            })),
                        })),
                        // reminders: [activeVersionApprovalDeadline]
                    })),
                },
            },
        };
        const data: {
            [key: string]: unknown;
        } = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const fieldData of fieldsData) {
            if (Array.isArray(fieldData.value as any)) {
                data[fieldData.name] = (fieldData.value as any).map((v: { value: any }) => {
                    if (v.value) {
                        return v.value;
                    }
                    return v;
                });
            } else {
                data[fieldData.name] = fieldData.value;
            }
        }
        let effectiveFrom = null;
        if (typeof effectiveFromDate === 'number') {
            effectiveFrom = effectiveFromDate;
        } else if (effectiveFromDate instanceof Date) {
            // Dividing by 1000 to remove milliseconds
            effectiveFrom = Math.floor(Date.parse(effectiveFromDate.toString()) / 1000);
        }
        let effectiveTill = null;
        if (!isEverGreen) {
            if (typeof effectiveTillDate === 'number') {
                effectiveTill = effectiveTillDate;
            } else if (effectiveTillDate instanceof Date) {
                // Dividing by 1000 to remove milliseconds
                effectiveTill = Math.floor(Date.parse(effectiveTillDate.toString()) / 1000);
            }
        }
        let effectivePeriod = null;
        if (!isEverGreen) {
            effectivePeriod = effectivePeriodTime;
        }
        const response = await putRequest(`documents/${documentId}/versions/${documentVersionId}`, {
            data,
            activeApprovalStatus,
            externalApprovalId,
            externalApprovers,
            effectiveFrom,
            effectiveTill,
            effectivePeriod,
            sunsetPeriod,
            isEndDateAsked,
            allowReviseByAnyone,
            isEverGreen,
            autoRenew,
            activeVersionApprovalDeadline,
            checklist,
        });
        if (response?.apiStatus === 'SUCCESS') {
            return { apiStatus: response.apiStatus, errorMessage: '' };
        }
        if (response?.apiStatus === 'FAILURE') {
            const values = JSON.parse(
                response.response.message.replace('Data validation failed ', ''),
            );
            let error = `Data Validation failed\n`;
            // eslint-disable-next-line no-restricted-syntax
            for (const value of values) {
                error += `${value.instancePath} ${value.message}\n`;
            }
            return { apiStatus: response.apiStatus, errorMessage: error };
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const updateApprovalStatus = async (
    activeApprovalStatusId: number,
    reviewers: IReviewers[],
    approversData: IApprovalDocStage[],
    ownerId: number,
    activeVersionApprovalDeadline: number,
    existingContract: boolean,
    documentId: number
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string } | undefined> => {
    try {
        const activeApprovalStatus = {
            documentId,
            id: activeApprovalStatusId,
            data: {
                existingContract,
                ownerId,
                reviewers: reviewers.map((r) => ({
                    value: r.reviewerId.toString(),
                    state: r.state,
                })),

                stages: {
                    value: approversData.map(({ approvers, ...rest }) => ({
                        ...rest,
                        approvers: approvers.map(({ employee, adHoc, ...rest1 }) => ({
                            ...rest1,
                            employeeId: employee.value ? employee.value.toString() : null,
                            reminders: [activeVersionApprovalDeadline],
                            // eslint-disable-next-line @typescript-eslint/no-shadow
                            adHOC: adHoc?.map(({ employee, ...rest2 }) => ({
                                ...rest2,
                                employeeId: employee.value ? employee.value.toString() : null,
                                reminders: [activeVersionApprovalDeadline],
                            }))
                        })),
                        // reminders: [activeVersionApprovalDeadline]
                    })),
                },
            },
        };
        const response = await putRequest(
            `documents/${activeApprovalStatusId}/update-approval-status`,
            {
                activeApprovalStatus
            },
        );
        if (response?.apiStatus === 'SUCCESS') {
            return { apiStatus: response.apiStatus, errorMessage: '' };
        }
        if (response?.apiStatus === 'FAILURE') {
            const values = JSON.parse(
                response.response.message.replace('Data validation failed ', ''),
            );
            let error = `Data Validation failed\n`;
            // eslint-disable-next-line no-restricted-syntax
            for (const value of values) {
                error += `${value.instancePath} ${value.message}\n`;
            }
            return { apiStatus: response.apiStatus, errorMessage: error };
        }
        return undefined;
    } catch {
        return undefined;
    }
};
const updateExternalApprovers = async (
    versionId: number,
    externalApprovers: IExternalApprover[]
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string } | undefined> => {
    try {
        const response = await putRequest(
            `documents/update-external-approver/${versionId}`,
            {
                externalApprovers
            },
        );

        if (response?.apiStatus) {
            return { apiStatus: response.apiStatus, errorMessage: '' };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
}
const updateDocumentVersionNumber = async (
    documentId: number,
    currDocumentVersionId: number,
    majorVersionNumber: number,
    minorVersionNumber: number,
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string; data: any } | undefined> => {
    try {
        const response = await putRequest(
            `documents/${documentId}/update-version/${currDocumentVersionId}`,
            {
                majorVersionNumber,
                minorVersionNumber,
            },
        );

        if (response?.apiStatus) {
            const [rowsUpdated, [updatedDocVersion]] = response?.response;
            return { apiStatus: response.apiStatus, errorMessage: '', data: updatedDocVersion };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const updateExistingContract = async (
    documentId: number,
    existingContract: boolean,
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string; data: any } | undefined> => {
    try {
        const response = await putRequest(`documents/${documentId}/updateExistingContract`, {
            existingContract,
        });

        if (response?.apiStatus) {
            const res = response?.response;
            return { apiStatus: response.apiStatus, errorMessage: '', data: res };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const updateDocumentVersionDeadline = async (
    documentId: number,
    activeDocVersionId: number,
    activeApprovalStatusId: number,
    approversData: IApprovalDocStage[],
    updatedDeadline: number,
    activeVersionOwnerId: number,
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string; data: any } | undefined> => {
    try {
        const approversObj = {
            stages: {
                value: approversData.map(({ approvers, ...rest }) => ({
                    ...rest,
                    approvers: approvers.map(({ employee, adHoc, ...rest1 }) => ({
                        ...rest1,
                        employeeId: employee.value ? employee.value.toString() : null,
                        // reminders: [updatedDeadline],
                        // eslint-disable-next-line @typescript-eslint/no-shadow
                        adHOC: adHoc?.map(({ employee, ...rest2 }) => ({
                            ...rest2,
                            employeeId: employee.value ? employee.value.toString() : null,
                            // reminders: [updatedDeadline],
                        })),
                    })),
                    // reminders: [activeVersionApprovalDeadline]
                })),
            },
            ownerId: activeVersionOwnerId,
            reviewers: [],
        };

        const response = await putRequest(
            `documents/${documentId}/update-version-deadline/${activeDocVersionId}`,
            {
                activeApprovalStatusId,
                approversObj,
                updatedDeadline,
            },
        );

        if (response?.apiStatus) {
            const res = response?.response;
            // console.log(`***in api doc: res: ${JSON.stringify(res)}`)
            return { apiStatus: response.apiStatus, errorMessage: '', data: res };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const updateDocument = async (
    title: string,
    description: string,
    confidential: boolean,
    documentLock: boolean,
    documentId: number,
    allowReviseByAnyone: boolean,
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string } | undefined> => {
    try {
        const response = await putRequest(`documents/${documentId}`, {
            title,
            description,
            confidential,
            documentLock,
            documentId,
            allowReviseByAnyone,
        });

        if (response?.apiStatus) {
            return { apiStatus: response.apiStatus, errorMessage: '' };
        }

        return undefined;
    } catch (err) {
        // console.log(err)
        return undefined;
    }
};

const updateFileDocument = async (
    title: string,
    description: string,
    confidential: boolean,
    documentLock: boolean,
    documentId: number,
    documentVersionId: number,
    allowReviseByAnyone: boolean,
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string; newVersionId?: number; newAttachments?: number[], wasRevised: boolean; } | undefined> => {
    try {
        let latestVersionId = documentVersionId;
        let revisedFlag = false;
        let errorMsg = '';
        let status: IAPIResponseStatus = 'FAILURE';
        let newAttachments = [];

        // fetch doc details
        const response = await getRequest(`documents/${documentId}`);
        status = response?.apiStatus ?? 'FAILURE';
        // console.log(`insideeee ${JSON.stringify(response?.response)}`)
        if (response?.apiStatus === 'SUCCESS') {
            const docState = response?.response.state;
            if (docState === 'PUBLISHED') {
                // revise the doc
                const docTypeInfo = {
                    allowReviseByAnyone: true
                }
                const body: any = {
                    documentId,
                    docTypeInfo,
                };
                body.type = 'MAJOR';
                const submitRes = await postRequest(`documents/revise`, body);
                if (submitRes && submitRes.apiStatus === 'SUCCESS') {
                    latestVersionId = Number(submitRes.response?.newVersionId);
                    revisedFlag = true;
                    status = 'SUCCESS';
                    newAttachments = submitRes.response?.newAttachments;
                } else if (submitRes && submitRes?.apiStatus !== 'SUCCESS') {
                    errorMsg = submitRes?.response.message;
                    status = submitRes?.apiStatus;
                }
            }
            const updateDocResponse = await putRequest(`documents/${documentId}`, {
                title,
                description,
                confidential,
                documentLock,
                documentId,
                allowReviseByAnyone,
            });
            const updatedFieldsData: IFieldsData[] = [{
                name: 'description',
                value: description
            }]
            const updateDocVersion = await updateDocumentVersionFieldsData(updatedFieldsData, documentId, latestVersionId, false);
        }

        if (response?.apiStatus) {
            return { apiStatus: status, errorMessage: errorMsg, newVersionId: latestVersionId, newAttachments, wasRevised: revisedFlag };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const submitDocument = async (
    documentId: number,
    submitterId: number,
    notifyTravelAdmin: boolean,
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        errorMessage: string;
        docTypeChanged: boolean;
        docNumber: string;
    }
    | undefined
> => {
    try {
        const submitRes = await postRequest(`documents/submit`, {
            documentId,
            submitterId,
            notifyTravelAdmin
        });
        if (submitRes) {
            let errorMessage = '';
            let docTypeChanged = false;
            let docNumber = '';
            if (submitRes?.apiStatus === 'FAILURE') {
                if (submitRes.responseCode === 412) {
                    docTypeChanged = true;
                }
                errorMessage = submitRes?.response.message;
            } else if (submitRes?.apiStatus === 'SUCCESS') {
                docNumber = submitRes.response.documentNumber;
            }
            return { apiStatus: submitRes.apiStatus, errorMessage, docTypeChanged, docNumber };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const documentActionType = {
    APPROVE: 'approve-by-taskId',
    DISCARD: 'discard', // discard, withdraw, revise, revert
    REJECT: 'reject-by-taskId',
    WITHDRAW: 'withdraw',
    REVISE: 'revise',
    REVERT: 'revert',
    REPUBLISH: 'republish',
    AD_HOC: '',
    FORWARD: '',
    REVIEWED: 'complete-review',
    CLAIM: 'claim',
    UNCLAIM: 'unclaim',
    EDIT: 'edit',
    TERMINATE: 'terminate',
    '': '',
    RERELEASE: 're-release',
    OBSOLETE: 'obsolete',
};

const documentAction = async (
    documentId: number,
    type: IDocumentActionTypes,
    docTypeInfo: IDocumentTypeInfo,
    taskId?: string,
    versionType?: 'MAJOR' | 'MINOR' | 'CUSTOM' | 'RERELEASE' | 'OBSOLETE',
    effectivityObj?: {
        effectiveFromDate: Date | undefined | number;
        endDate: boolean;
        effectiveTillDate: Date | undefined | number;
        effectivePeriod: number;
        sunsetPeriod: number;
    },
    customVersionObj?: IVersionNumberInfo,
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string; responseCode?: number } | undefined> => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body: any = {
            documentId,
            docTypeInfo,
        };
        if (type === 'REVISE') {
            body.type = versionType;
            if (versionType === 'CUSTOM') {
                body.customVersionObj = customVersionObj;
            }
        }
        if (type === 'APPROVE' || type === 'REJECT') {
            body.taskId = taskId;
        }
        if (type === 'REPUBLISH') {
            let efd;
            let eft;
            if (
                effectivityObj?.effectiveFromDate &&
                effectivityObj?.effectiveFromDate instanceof Date
            ) {
                efd = Math.floor(Date.parse(effectivityObj?.effectiveFromDate.toString()) / 1000);
            } else {
                efd = effectivityObj?.effectiveFromDate;
            }
            if (
                effectivityObj?.effectiveTillDate &&
                effectivityObj?.effectiveTillDate instanceof Date
            ) {
                eft = Math.floor(Date.parse(effectivityObj?.effectiveTillDate.toString()) / 1000);
            } else {
                eft = effectivityObj?.effectiveFromDate;
            }
            if (effectivityObj?.endDate) {
                body.effectiveFrom = efd;
                body.effectiveTill = eft;
                body.effectivePeriod = body.effectiveTill - body.effectiveFrom;
                body.sunsetPeriod = effectivityObj && effectivityObj?.sunsetPeriod * 604800;
            } else {
                body.effectiveFrom = efd;
                body.effectivePeriod = effectivityObj && effectivityObj?.effectivePeriod * 604800;
                body.sunsetPeriod = effectivityObj && effectivityObj?.sunsetPeriod * 604800;
            }
        }
        if (type === 'TERMINATE') {
            body.sunsetPeriod = effectivityObj && effectivityObj?.sunsetPeriod * 86400;
            body.effectiveTill = Math.floor(new Date().getTime() / 1000) + body.sunsetPeriod;
        }
        let prefix;
        if (
            type === 'WITHDRAW' ||
            type === 'REVISE' ||
            type === 'REVERT' ||
            type === 'DISCARD' ||
            type === 'REPUBLISH' ||
            type === 'TERMINATE'
        ) {
            prefix = 'documents';
        } else {
            prefix = 'tasks';
        }
        const submitRes = await postRequest(`${prefix}/${documentActionType[type]}`, body);
        if (submitRes) {
            let errorMessage = '';
            if (submitRes?.apiStatus === 'FAILURE') {
                errorMessage = submitRes?.response.message;
            }
            return { apiStatus: submitRes.apiStatus, errorMessage, responseCode: submitRes?.responseCode };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const documentActionClaimUnclaim = async (
    type: IDocumentActionTypes,
    taskId?: string,
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string } | undefined> => {
    try {
        const body: any = {
            taskId,
        };
        const submitRes = await postRequest(`tasks/${documentActionType[type]}`, body);
        if (submitRes) {
            let errorMessage = '';
            if (submitRes?.apiStatus === 'FAILURE') {
                errorMessage = submitRes?.response.message;
            }
            return { apiStatus: submitRes.apiStatus, errorMessage };
        }
        return undefined;
    } catch (e) {
        return undefined;
    }
};

const documentActionForward = async (
    documentId: number,
    newApprover: number,
    taskId: string,
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string } | undefined> => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body: any = {
            documentId,
            newApprover,
            taskId,
        };

        const submitRes = await postRequest(`tasks/forward-by-taskId`, body);
        if (submitRes) {
            let errorMessage = '';
            if (submitRes?.apiStatus === 'FAILURE') {
                errorMessage = submitRes?.response.message;
            }
            return { apiStatus: submitRes.apiStatus, errorMessage };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const documentActionAddApprover = async (
    documentId: number,
    approvers: string[],
    taskId: string,
): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string } | undefined> => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body: any = {
            documentId,
            approvers,
            taskId,
        };

        const submitRes = await postRequest(`tasks/adhoc-approver-by-taskId`, body);
        if (submitRes) {
            let errorMessage = '';
            if (submitRes?.apiStatus === 'FAILURE') {
                errorMessage = submitRes?.response.message;
            }
            return { apiStatus: submitRes.apiStatus, errorMessage };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const getDocumentTypeInfo = async (
    documentId: number,
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        docTypeConfig: IDocTypeConfig;
    }
    | undefined
> => {
    try {
        const res = await getRequest(`documents/doc-type-info/${documentId}`);
        if (res?.apiStatus === 'SUCCESS') {
            return {
                apiStatus: res.apiStatus,
                docTypeConfig: res.response,
            };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const convertEpochtoDate = (timestamp: number) => {

    const date = new Date(timestamp * 1000);

    // Get the day, month, year, hours, and minutes
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Determine AM/PM and format hours
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    // Format the day with the appropriate suffix
    const getDayWithSuffix = (day1: any) => {
        if (day1 > 3 && day1 < 21) return `${day1}th`;
        switch (day1 % 10) {
            case 1: return `${day1}st`;
            case 2: return `${day1}nd`;
            case 3: return `${day1}rd`;
            default: return `${day1}th`;
        }
    };

    // Combine into the desired format
    const formattedDate = `${getDayWithSuffix(day)} ${month}, ${year}, ${hours}:${minutes} ${ampm}`;
    return formattedDate;
}

const fetchDocumentHistory = async (
    documentId: number,
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        data: IDocumentHistory[];
    }
    | undefined
> => {
    try {
        const historyData: IDocumentHistory[] = [];
        const res = await getRequest(`documents/DCO-history/${documentId}`);
        if (res?.apiStatus === 'SUCCESS') {
            for (const data of res.response) {
                const { DCOInfo, versionInfo } = data;
                const effectiveDate =
                    versionInfo.effectiveFrom === null
                        ? versionInfo.effectiveFrom
                        : new Date(Number(versionInfo.effectiveFrom * 1000));
                historyData.push({
                    docId: DCOInfo.id,
                    changeNumber: DCOInfo.documentNumber,
                    revision: `${versionInfo.majorVersionNumber}.${versionInfo.minorVersionNumber}`,
                    revisionStatus: versionInfo.state,
                    effectiveDate,
                    phase: versionInfo.versionState,
                    creator: DCOInfo.owner.employeeName,
                    notes: DCOInfo.title,
                });
            }

            return {
                apiStatus: res.apiStatus,
                data: historyData,
            };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const getaction = (activity: any) => {
    let action = "";

    // eslint-disable-next-line default-case
    switch (activity.activityTypeName) {
        case "Document.Saved":
            action = "Modified";
            break;
        case "Document.Created":
            action = "Created";
            break;
        case "Document.CheckedIn":
            action = "Document CheckedIn";
            break;
        case "Document.CheckedOut":
            action = "Document CheckedOut";
            break;
        case "Document.CommentAdded":
            action = `Comment Added - "${activity.comment}"`;
            break;
        case "Document.CommentEdited":
            action = `Comment Edited to "${activity.comment}"`;
            break;
        case "Document.CommentDeleted":
            action = `Comment Deleted - "${activity.comment}"`;
            break;
        case "Document.AttachmentRemoved":
            action = `Attachment Removed - "${activity.comment}"`;
            break;
        case "Document.AttachmentUpload":
            action = `Attachment Uploaded - "${activity.comment}"`;
            break;
        case "Document.VersionChange":
            action = `Version Changed to "${activity.comment}"`;
            break;
        case "Document.OwnerChange":
            action = `Owner Changed to "${activity.details?.owner}"`;
            break;
        case "Document.Published":
            action = "Published";
            break;
        case "Document.AddedToDCO":
            action = `Document Added To DCO : "${activity.comment}"`;
            break;
        case "Document.RemovedFromDCO":
            action = `Document Removed From DCO : "${activity.comment}"`;
            break;
        case "Document.SubmittedToDCON":
            action = `Submitted To Document Control`;
            break;
        case "Document.RoutedForApproval":
            action = `Routed For Approval`;
            break;
        case "Document.Approved":
            action = `Approved`;
            break;
        case "Document.ApprovedByDCON":
            action = `Approved by Document Control`;
            break;
        case "Document.Rejected":
            action = `Rejected`;
            break;
        case "Document.RejectedByDCON":
            action = `Rejected by Document Control`;
            break;
        case "Document.Withdrawn":
            action = `Withdrawn`;
            break;
        case "Document.Claimed":
            action = `Claimed`;
            break;
        case "Document.Unclaimed":
            action = `Unclaimed`;
            break;
    }
    return action;
}

const fetchDocumentActivity = async (
    queryParams: IStringObject
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        data: IDocumentActivity[];
        totalActivityCount: number;
    }
    | undefined
> => {
    try {
        const documentActivity: IDocumentActivity[] = [];
        const res = await getRequest(`documents/documentActivity?${qs.stringify(queryParams)}`);
        if (res?.apiStatus === 'SUCCESS') {
            const activities = res.response.rows;
            for (const activity of activities) {
                documentActivity.push({
                    activityId: activity.id,
                    docId: activity.documentVersion.documentId,
                    docVersionId: activity.documentVersionId,
                    currentMajorVersionNumber: activity.documentVersion.majorVersionNumber,
                    currentMinorVersionNumber: activity.documentVersion.minorVersionNumber,
                    currentVersion: `${activity.details.majorVersionNumber}.${activity.details.minorVersionNumber} - ${activity.activityTypeName === "Document.Published" ? "Published" : "Draft"}`,
                    activityTypeName: activity.activityTypeName,
                    comment: activity.comment,
                    action: getaction(activity),
                    actorName: activity.actor?.employeeName ? activity.actor.employeeName : "System",
                    actionTime: convertEpochtoDate(Number(activity.actionTime)),
                    Title: activity.details?.title ? activity.details.title : null,
                    Description: activity.details?.description ? activity.details.description : null,
                    Owner: activity.details?.owner ? activity.details?.owner : null,
                    'DCO Creator': activity.details?.DCOCreator ? activity.details?.DCOCreator : null,
                    'Item Creator': activity.details?.itemCreator ? activity.details?.itemCreator : null,
                    Submitter: activity.details?.submitter ? activity.details?.submitter : null,
                    savedMajorVersionNumber: activity.details.majorVersionNumber,
                    savedMinorVersionNumber: activity.details.minorVersionNumber,
                    Version: `${activity.details.majorVersionNumber}.${activity.details.minorVersionNumber}`,
                    Category: activity?.details?.data?.category ? activity?.details?.data?.category[0] : null,
                    Attachments: activity?.details?.attachments ? activity?.details?.attachments : null,
                    Approvers: activity.details?.allApprovers ? activity.details?.allApprovers : null,
                    checklist: activity.documentVersion?.checklist ? activity.documentVersion?.checklist : null,
                    'Documents': activity?.details?.data?.documents ? activity?.details?.data?.documents : null,
                })
            }
            return {
                apiStatus: res?.apiStatus,
                data: documentActivity,
                totalActivityCount: res.response.count
            }
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const fetchParentReferenceDocumentsTreeNodes = async (
    activeDocVersionId: number,
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        data: IRefDocsData;
    }
    | undefined
> => {
    try {
        const res = await getRequest(
            `documents/versions/${activeDocVersionId}/parent-linked-documents`,
        );
        if (res?.apiStatus === 'SUCCESS') {
            return {
                apiStatus: res.apiStatus,
                data: res.response,
            };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const fetchChildReferenceDocumentsTreeNodes = async (
    activeDocVersionId: number,
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        data: IRefDocsData;
    }
    | undefined
> => {
    try {
        const res = await getRequest(
            `documents/versions/${activeDocVersionId}/child-linked-documents`,
        );
        if (res?.apiStatus === 'SUCCESS') {
            return {
                apiStatus: res.apiStatus,
                data: res.response,
            };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const removeApprovalFlow = async (
    documentId: number,
    versionId: number,
    taskId: string
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        response: any;
    }
    | undefined
> => {
    try {
        const body: any = {
            documentId,
            versionId,
            taskId
        };
        const res = await postRequest(
            `documents/removeApprovalFlow`, body
        );
        if (res?.apiStatus === 'SUCCESS') {
            return {
                apiStatus: res.apiStatus,
                response: res.response
            };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

export {
    editToCcFields,
    getAllEmailData,
    createDocument,
    makeDocumentObsolete,
    getDocument,
    getDocumentsDetails,
    getDocumentsReferenceDetails,
    getDocumentVersion,
    updateDocumentVersion,
    updateAutoRenew,
    getDocumentRelationsInfo,
    toggleDocumentLock,
    submitDocument,
    updateDocument,
    updateFileDocument,
    documentAction,
    documentActionForward,
    documentActionAddApprover,
    exportPDF,
    downloadPDF,
    getTaskDetails,
    documentActionClaimUnclaim,
    getDocumentTypeInfo,
    updateDCOfields,
    updateDocumentVersionFieldsData,
    getActiveTask,
    updateDocumentVersionNumber,
    updateDocumentVersionDeadline,
    checkInDocument,
    fetchDocumentHistory,
    fetchDocumentActivity,
    fetchParentReferenceDocumentsTreeNodes,
    fetchChildReferenceDocumentsTreeNodes,
    getAllDocVersionIds,
    createNDA,
    createEventForEditDocument,
    updateApprovlStatusForTravelForm,
    removeApprovalFlow,
    updateApprovalStatus,
    updateExternalApprovers,
    updateExistingContract,
    updateApprovalStatusForLPNF,
    updateApprovalStatusForPRBForm,
};
