/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Column, useTable } from 'react-table';
import './ActiveSessions.scss';
import ConfirmationModal from './ConfirmationModal';
import { destroySessionById, fetchActiveSessions } from '../../API/session';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { ISession } from '../../interfaces/session';
import ActiveSessionsSkeleton from './ActiveSessionsSkeleton';
import { useNavigate } from 'react-router-dom';
import PuffLoader from 'react-spinners/PuffLoader';
import CONSTANTS from '../../constants';

const ActiveSessions: React.FC = () => {
    const [sessions, setSessions] = useState<ISession[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [sessionToBeDestroyed, setSessionToBeDestroyed] = useState<ISession | null>(null);
    const { user, onLogout } = useAuthDataContext();
    const navigate = useNavigate();
    const userId = user.id;

    useEffect(() => {
        const loadSessions = async () => {
            setLoading(true);
            const activeSessions = await fetchActiveSessions(userId);
            if (activeSessions.length === 1) {
                const redirectTo = localStorage.getItem('redirectTo') || '/dashboard';
                localStorage.removeItem('redirectTo');
                navigate(redirectTo);
            } else {
                setSessions(activeSessions);
                setLoading(false);
            }
        };
        loadSessions();
    }, [userId, history]);

    const destroySession = (session: ISession) => {
        setSessionToBeDestroyed(session);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSessionToBeDestroyed(null);
    };

    const handleDestroy = async (session: ISession) => {
        if (session.isCurrent) await onLogout();
        const success = await destroySessionById(session.sessionId);
        if (success) {
            setSessions((prevSessions) =>
                prevSessions.filter((s) => s.sessionId !== session.sessionId),
            );
            closeModal();
        }
    };

    // Define table columns
    const columns: Column<ISession>[] = React.useMemo(
        () => [
            { Header: 'IP Address', accessor: 'ipAddress' },
            {
                Header: 'Browser',
                accessor: 'browser',
                Cell: ({ row }: { row: { original: ISession } }) => (
                    <div>
                        {row.original.browser}{' '}
                        {row.original.isCurrent && <span className="current-pill">Current</span>}
                    </div>
                ),
            },
            {
                Header: 'Login Time',
                accessor: 'loginTime',
                Cell: ({ value }: { value: string }) => new Date(value).toLocaleString(),
            },
            {
                Header: 'Actions',
                accessor: 'sessionId',
                Cell: ({ row }: { row: { original: ISession } }) => (
                    <button
                        className="destroy-btn"
                        onClick={() => destroySession(row.original)}
                        type="button"
                    >
                        Delete Session
                    </button>
                ),
            },
        ],
        [],
    );

    const tableInstance = useTable({ columns, data: sessions });

    if (loading)
        return (
            <div className="puff-loader">
                <PuffLoader color={CONSTANTS.BRAND_COLOR} size={100} />
            </div>
        );

    return (
        <div className="active-sessions">
            <div className="warning-message">
                <div>
                    <h1>Multiple Active Sessions Detected for DMS Application!!</h1>
                    <p>You’re currently signed in on multiple browsers or devices. Click &quot;Continue Session&quot; to stay logged in here.</p>
                    {/* <p>
                        You can click on the &quot;Continue Session&quot; button to proceed with
                        login on current browser session.
                    </p> */}
                    {/* <p>You can use the list below to manage your other active sessions.</p> */}
                </div>
                <div className="continue-sessions-button">
                    <button
                        className="primary-btn"
                        onClick={() => {
                            const redirectTo = localStorage.getItem('redirectTo') || '/dashboard';
                            localStorage.removeItem('redirectTo');
                            navigate(redirectTo);
                        }}
                        disabled={loading}
                        type="button"
                    >
                        Continue Session
                    </button>
                </div>
            </div>
            <div className="active-sessions-header">
                <h1>Active Sessions</h1>
            </div>

            <table className="active-sessions-table">
                <thead>
                    {tableInstance.headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {tableInstance.rows.map((row) => {
                        tableInstance.prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {showModal && sessionToBeDestroyed && (
                <ConfirmationModal
                    show={showModal}
                    session={sessionToBeDestroyed}
                    handleDestroy={handleDestroy}
                    closeModal={closeModal}
                />
            )}
        </div>
    );
};

export default ActiveSessions;
