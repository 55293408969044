import React from 'react';
import Modal from 'react-modal';
import { ISession } from '../../interfaces/session';
import { PlusIconSvg } from '../../assets/images';
import './ConfirmationModel.scss';

interface Props {
    session: ISession;
    handleDestroy: (session: ISession) => void;
    closeModal: () => void;
    show: boolean;
}
const ConfirmationModal: React.FC<Props> = ({ session, handleDestroy, closeModal, show }) => (
    <>
        <Modal isOpen={show} onRequestClose={closeModal} className="confirmation-modal">
            <PlusIconSvg className="close-btn" onClick={closeModal} />
            <div className="modal-body">
                <div className="heading"><h1>Confirm Deletion</h1></div>

                <h4>Are you sure you want to delete Session</h4>
                <div className="button-group">
                    <button
                        type="button"
                        className="primary-btn"
                        onClick={() => {
                            handleDestroy(session);
                        }}
                    >
                        Yes
                    </button>
                    <button
                        type="button"
                        onClick={closeModal}
                    >
                        No
                    </button>
                </div>
            </div>
        </Modal>
    </>
);

export default ConfirmationModal;
