/* eslint-disable no-console */
import React, { useEffect } from 'react';
import PuffLoader from "react-spinners/PuffLoader";

import axios from 'axios';
import CONSTANTS from '../../constants';

// eslint-disable-next-line max-lines-per-function
const Auth: React.FC = () => {
    const handleSignIn = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/auth/PKCEAuthCodeFirstStep`, {
                withCredentials: true,
            });
            const firstStep = response.data.url;
            window.location.href = firstStep;
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        handleSignIn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="puff-loader">
            <PuffLoader color={CONSTANTS.BRAND_COLOR} size={100} />
        </div>
    );
};

export default Auth;
