/* eslint-disable max-lines-per-function */
import React, { createContext, useState, useEffect, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import { IUser } from '../interfaces/user';
import { convertToEpoch } from '../utils';
import { getUserFromMail } from '../API/users';
import { useGAUser } from '../hooks/useGoogleAnalytics';
import { getFromInMemory, setInMemory } from '../utils/in-memory-storage';
import { postRequest } from '../utils/api-request';

const initialAuthData: IUser = { id: -1, name: '', role: '', mail: '', isAdmin: false, isClmAdmin: false, isDcoAdmin: false, jobTitle: '', isTrapAdmin: false };
const noop = () => { };

interface IUserAuthContext {
    user: IUser;
    onLogin: () => void;
    onLogout: () => void;
    tokenValid: boolean;
    isLoading: boolean;
    getUser: () => void;
}
export const AuthDataContext = createContext<IUserAuthContext>({
    user: initialAuthData,
    onLogin: noop,
    onLogout: noop,
    tokenValid: false,
    isLoading: true,
    getUser: () => null,
});

const getUserMailId = (): string => {
    let userMailId = '';
    const tokenStr = getFromInMemory();
    if (tokenStr) {
        const token = jwtDecode(tokenStr) as { exp: number; unique_name?: string; preferred_username: string };
        if (token) {
            if (typeof token.exp === 'undefined' || token.exp < convertToEpoch(new Date()) / 1000) {
                userMailId = '';
            }
            userMailId = token.preferred_username;
        }
    }

    return userMailId;
};
interface AuthParamsProviderProps {
    children: React.ReactNode;
}

const AuthenticationProvider: React.FC<AuthParamsProviderProps> = ({ children }) => {
    const [mailId, setMailId] = useState('');
    const [user, setUser] = useState(initialAuthData);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const isAllowed = localStorage.getItem('X-Puppeteer') === '1234567';
    const localToken = localStorage.getItem('token');

    const getUser = async () => {
        if (isAllowed && localToken) {
            setInMemory(localToken);
            setIsLoading(false);
            return;
        }
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/auth/access-token`, {
            credentials: 'include',
        });
        if (!res || !res.ok) {
            const relativePath = window.location.href.replace(window.location.origin + "/app", '');
            localStorage.setItem('redirectTo', relativePath);
            navigate('/signin');
        }
        const data = await res.json();
        setInMemory(data.id_token);
        const tokenStr = getFromInMemory();
        if (tokenStr) {
            const token = jwtDecode(tokenStr) as { exp: number; unique_name?: string; preferred_username: string };
            if (token) {
                const userInfo = await getUserFromMail(token.preferred_username);
                if (userInfo?.apiStatus === 'SUCCESS') {
                    setUser(userInfo.user);
                } else if (userInfo?.apiStatus === 'UNAUTHORIZED') {
                    navigate('/401');
                    return;
                }
            }
        }
        setIsLoading(false);
    };
    useEffect(() => {
        if (user.id !== -1) {
            setMailId(getUserMailId());
        }
    }, [user]);

    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        if (user) useGAUser(user.id);
    }, [user]);

    const onLogout = async () => {
        const res = await postRequest(`auth/logout`, {});
        document.cookie.split(';').forEach((c) => {
            document.cookie = c.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
        });
        localStorage.clear();
        setInMemory('');
        window.location.assign(`${process.env.REACT_APP_LOG_OUT_REDIRECT}`);
    };

    const onLogin = () => {
        setIsLoading(true);
        getUser();
    };

    return (
        <AuthDataContext.Provider
            value={{ user, onLogin, onLogout, tokenValid: !!mailId, isLoading, getUser }}
        >{children}</AuthDataContext.Provider>
    );
};

export const useAuthDataContext = (): IUserAuthContext => useContext(AuthDataContext);

export default AuthenticationProvider;
