import React, { useEffect, useMemo, useState } from 'react';
import {
    Row,
    Column,
    usePagination,
    useTable,
    useSortBy,
    useAsyncDebounce,
    useGlobalFilter,
    useFilters,
} from 'react-table';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { IAdminDocumentTypeConfigTable } from '../../interfaces';
import { SearchIconSvg, SortIcon, TickIcon, CrossIcon } from '../../assets/images';
import Pagination from '../../components/pagination/Pagination';
import './AdminDocTypeTable.scss';
import CONSTANTS from '../../constants';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { DateColumn, NoDocumentsFound, StatusColumn } from '../../components/General';
import STATIC_CONTENT from '../../constants/StaticContent';
import AdminSideNavigation from '../../components/admin-page/AdminSideNavigation';
import '../../pages/admin-dashboard/AdminDashboard.scss';

const { DOCUMENT_TYPES } = STATIC_CONTENT;

interface Props {
    docTypeData: IAdminDocumentTypeConfigTable[];
}

type ConfigValue = true | false | undefined;

const DocTypeConfigIcon = ({ value }: { value: ConfigValue }) => (
    <div>
        {value ? <TickIcon width="34px" height="34px" /> : <CrossIcon width="34px" height="34px" />}
    </div>
);

type ConfigNonBoolean = number | string | undefined;

const DocTypeConfig = ({ value }: { value: ConfigNonBoolean }) => (
    <div className="cell-value-margin">{value || '-'}</div>
);

// eslint-disable-next-line max-lines-per-function
const DocTypeLinkColumn = (row: Row<IAdminDocumentTypeConfigTable>) => {
    const {
        original: {
            id,
            activeVersion,
            documentType,
            createdDate,
            lastUpdateDate,
            status,
            totalDocuments,
        },
    } = row;
    const { user } = useAuthDataContext();
    const clickHandler = async () => {
        document.body.classList.remove('admin-side-navigation');
    };
    return (
        <div className="document-type">
            {!user.isAdmin && user.isDcoAdmin ? (
                <>{documentType}</>
            ) : (
                <a
                    href={`${CONSTANTS.RELATIVE_PATHS.documentTypeCreate.WPUrl}?doc_type_id=${id}&doc_type_v_id=${activeVersion}&prev_page_type=ADMIN_DOC_TYPE`}
                    onClick={clickHandler}
                >
                    {documentType}
                </a>
            )}

            <div className="tool-tip">
                <div className="block-margin">
                    <div>Total Documents</div>
                    <div className="tool-tip-data">{totalDocuments}</div>
                </div>
                <div className="block-margin">
                    <div>Created On</div>
                    <div className="tool-tip-data">
                        <DateColumn value={createdDate} />
                    </div>
                </div>
                <div className="block-margin">
                    <div>Last Updated</div>
                    <div className="tool-tip-data">
                        <DateColumn value={lastUpdateDate} />
                    </div>
                </div>
                <div className="block-margin">
                    <div>Status</div>
                    <StatusColumn value={status} />
                </div>
            </div>
        </div>
    );
};

const DocNumberConfig = (row: Row<IAdminDocumentTypeConfigTable>) => {
    const {
        original: { prefixCode, strategyUserInitials },
    } = row;
    const { user } = useAuthDataContext();
    const employeeInitials = user.mail.split('@')[0];
    return <>{`${prefixCode}${strategyUserInitials ? `-${employeeInitials}` : ''}-00001`}</>;
};

const DocTypeGenerationTrigger = ({ value }: { value: string }) => (
    <div className={`status-pill ${!value || value === 'ON_SUBMIT' ? 'submit' : 'create'}`}>
        {!value || value === 'ON_SUBMIT' ? 'Submit' : 'Create'}
    </div>
);

function GlobalFilter({
    globalFilter,
    setGlobalFilter,
}: {
    globalFilter: any;
    setGlobalFilter: any;
}) {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((val) => {
        setGlobalFilter(val || undefined);
    }, 200);
    const createDocType = async () => {
        navigate(`/document-type/create?prev_page_type=ADMIN_DOC_TYPE`);
    };
    const { user } = useAuthDataContext();

    return (
        <div className="filter-search-input">
            <span>
                <input
                    value={value || ''}
                    onChange={(e) => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder="Search..."
                />
                {!value && <SearchIconSvg className="search-icon" />}
            </span>
            {user.isAdmin && (
                <button type="button" className="primary-btn" onClick={() => createDocType()}>
                    {DOCUMENT_TYPES.BUTTON.CREATE_NEW}
                </button>
            )}
        </div>
    );
}

function SelectColumnFilter({ column: { setFilter } }: any) {
    const options = [
        { value: '', label: 'All' },
        { value: 'enabled', label: 'Enabled' },
        { value: 'disabled', label: 'Disabled' },
    ];
    const handleChange = (option: any) => {
        let thing: any = false;
        if (option.value) {
            if (option.value === 'enabled') {
                thing = true;
            }
        } else {
            thing = undefined;
        }
        setFilter(thing);
    };
    return (
        <Select
            name="BooleanFilter"
            id="BooleanFilter"
            isMulti={false}
            className="react-select"
            classNamePrefix="select"
            options={options}
            onChange={handleChange}
            defaultValue={options[0]}
        />
    );
}

function MultiSelectColumnFilter({ column: { setFilter, preFilteredRows, id } }: any) {
    const options = React.useMemo(() => {
        const setOptions = new Set<string>();
        preFilteredRows.forEach((row: any) => {
            setOptions.add(row.values[id]);
        });
        return [...setOptions.values()];
    }, [id, preFilteredRows]);
    const multiOptions = options.map((o) => ({ value: o, label: o }));
    const handleMultiChange = (value: any) => {
        const allValues = value && value.length > 0 ? value.map((v: any) => v.value) : [];
        setFilter(allValues && allValues.length ? allValues : undefined);
    };
    return (
        <Select
            name="MultiSelectFilter"
            id="MultiSelectFilter"
            isMulti
            className="react-select"
            classNamePrefix="select"
            options={multiOptions}
            onChange={handleMultiChange}
            placeholder="Select Document Type..."
        />
    );
}

function SelectColumnGenerateFilter({ column: { setFilter } }: any) {
    const options = [
        { value: '', label: 'All' },
        { value: 'submit', label: 'Submit' },
        { value: 'create', label: 'Create' },
    ];
    const handleChange = (option: any) => {
        let buttonValue: any = 'ON_CREATE';
        if (option.value) {
            if (option.value === 'submit') {
                buttonValue = 'ON_SUBMIT';
            }
        } else {
            buttonValue = undefined;
        }
        setFilter(buttonValue);
    };
    return (
        <Select
            name="BooleanGenerateFilter"
            id="BooleanGenerateFilter"
            isMulti={false}
            className="react-select"
            classNamePrefix="select"
            options={options}
            onChange={handleChange}
            defaultValue={options[0]}
        />
    );
}

const ADMIN_DOCUMENT_TYPE_COLUMNS: Column<IAdminDocumentTypeConfigTable>[] = [
    {
        Header: 'Document Type',
        accessor: 'documentType',
        Cell: ({ row }) => <DocTypeLinkColumn {...row} />,
        width: 200,
        disableSortBy: true,
        Filter: MultiSelectColumnFilter,
        filter: 'multiple',
    },
    {
        Header: 'Document Type Is Confidential',
        accessor: 'isConfidential',
        Cell: ({ value }) => <DocTypeConfigIcon value={value} />,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Collect Comment While Approving',
        accessor: 'collectComment',
        Cell: ({ value }) => <DocTypeConfigIcon value={value} />,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Collect Comment While Rejecting',
        accessor: 'collectCommentRejection',
        Cell: ({ value }) => <DocTypeConfigIcon value={value} />,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Collect Comment While Withdrawing',
        accessor: 'collectCommentWithdraw',
        Cell: ({ value }) => <DocTypeConfigIcon value={value} />,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Allow Export',
        accessor: 'allowExport',
        Cell: ({ value }) => <DocTypeConfigIcon value={value} />,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Is DCO',
        accessor: 'isDCO',
        Cell: ({ value }) => <DocTypeConfigIcon value={value} />,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Allow For DCO',
        accessor: 'allowDCO',
        Cell: ({ value }) => <DocTypeConfigIcon value={value} />,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Anyone Can Revise',
        accessor: 'allowReviseByAnyone',
        Cell: ({ value }) => <DocTypeConfigIcon value={value} />,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Anyone Can View In Process Doc',
        accessor: 'allowViewingInProcessDocByAnyone',
        Cell: ({ value }) => <DocTypeConfigIcon value={value} />,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Anyone can edit draft',
        accessor: 'anyoneCanEditDraft',
        Cell: ({ value }) => <DocTypeConfigIcon value={value} />,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Allow Custom Versioning',
        accessor: 'allowCustomVersioning',
        Cell: ({ value }) => <DocTypeConfigIcon value={value} />,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Block Revise',
        accessor: 'blockRevise',
        Cell: ({ value }) => <DocTypeConfigIcon value={value} />,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Enable Sunset Phase',
        accessor: 'isSunsetEnabled',
        Cell: ({ value }) => <DocTypeConfigIcon value={value} />,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Max Republish',
        accessor: 'maxRepublishCount',
        Cell: ({ value }) => <DocTypeConfig value={value} />,
        Filter: <></>,
        disableSortBy: true,
    },
    {
        Header: 'Publish Once Approved',
        accessor: 'publishOnceApproved',
        Cell: ({ value }) => <DocTypeConfigIcon value={value} />,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Generate Number on',
        accessor: 'generationTrigger',
        Cell: ({ value }) => <DocTypeGenerationTrigger value={value} />,
        disableSortBy: true,
        Filter: SelectColumnGenerateFilter,
        filter: 'equals',
    },
    {
        Header: 'Sample Doc Number',
        accessor: 'prefixCode',
        Cell: ({ row }) => <DocNumberConfig {...row} />,
        Filter: <></>,
    },
];

// eslint-disable-next-line max-lines-per-function
const AdminDocTypeTable: React.FC<Props> = ({ docTypeData }) => {
    const filterTypes = React.useMemo(
        () => ({
            multiple: (rows: any, id: any, filterValue: any) =>
                rows.filter((row: any) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined ? filterValue.includes(rowValue) : true;
                }),
        }),
        [],
    );
    const [data, setData] = useState<IAdminDocumentTypeConfigTable[]>([]);
    useEffect(() => {
        if (docTypeData) {
            setData(docTypeData);
        }
    }, [docTypeData]);
    const columns = useMemo(() => ADMIN_DOCUMENT_TYPE_COLUMNS, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize },
        canPreviousPage,
        canNextPage,
        state,
        setGlobalFilter,
    } = useTable<IAdminDocumentTypeConfigTable>(
        {
            columns,
            data,
            filterTypes,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    return (
        <div className='admin-dashboard'>
            <AdminSideNavigation />
            <div className="admin-doc-type-table admin-dashboard-main">
                <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        {column.isSorted && (
                                            <SortIcon
                                                className={`sort ${column.isSortedDesc ? 'desc' : 'asc'
                                                    } `}
                                            />
                                        )}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <Pagination
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                />
                {page.length === 0 && <NoDocumentsFound heading="No Document Types Found" />}
            </div>
        </div>
    );
};

export default AdminDocTypeTable;
