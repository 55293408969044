/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef, useState } from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import { Oval } from 'react-loader-spinner';
import Modal from 'react-modal';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createDocument } from '../../API/document';
import { getDocTypes } from '../../API/document-type';
import { DocumentTypesIconSvg, LogoSvg, PlusIconSvg } from '../../assets/images';
import InitialFeaturesTour from '../../components/introJs/InitialFeaturesTour';
import SearchBar from '../../components/search-bar/SearchBar';
import UserHeader from '../../components/user-header/UserHeader';
import CONSTANTS from '../../constants';
import STATIC_CONTENT from '../../constants/StaticContent';
import { useSearch } from '../../contexts/search-provider';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { IDocTypePopUp } from '../../interfaces/document-type';
import DocCreateModal from '../doc-create-modal/DocCreateModal';
import './Header.scss';

const { HEADER } = STATIC_CONTENT;

const Logo: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div
            className="logo"
            onClick={() => navigate({ pathname: `/${CONSTANTS.RELATIVE_PATHS.dashboard.Url}` })}
            role="presentation"
        >
            <LogoSvg />
            <span />
            <h1>{HEADER.LABEL.DOC_MANAGEMENT_SYSTEM}</h1>
        </div>
    );
};

const Header: React.FC = () => {
    const { user } = useAuthDataContext();
    const { showSearch, setShowSearch } = useSearch();
    const [showDocCreateModal, setShowDocCreateModal] = useState(false);
    const [showDashboardDropdown, setShowDashboardDropdown] = useState(false);
    const [showCreateDropdown, setShowCreateDropdown] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    const [selectedDocType, setSelectedDocType] = useState<IDocTypePopUp>({
        id: 0,
        name: '',
        description: '',
        updatedAt: new Date(),
        code: '',
        fields: [],
        isAllowedForDCO: false,
        isDCO: false,
    });
    const [docTypesList, setDocTypesList] = useState<IDocTypePopUp[]>([]);
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [initialStep, setInitialStep] = useState(0);
    const [showTourButton, setshowTourButton] = useState(false);
    const [createChangeBtnLoading, setCreateChangeBtnLoading] = useState(false);
    const [showLeaveModal, setLeaveModal] = useState(false);
    const createDocumentButtonClicked = useRef(false);
    const createDCOButtonClicked = useRef(false);
    const [btnClicked, setBtnCLicked] = useState(false);
    const [leaveBtnLoading, setLeaveBtnLoading] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (location.pathname.includes(CONSTANTS.RELATIVE_PATHS.dashboard.Url)) {
            setshowTourButton(true);
        } else {
            setshowTourButton(false);
        }
    }, [location]);
    if (!user.id) {
        return <Navigate to="/signin" />;
    }

    const handleDocumentType = async () => {
        navigate(`/adm/dashboard`);
    };

    const handleCreateClick = (e: any) => {
        e.stopPropagation();
        setShowCreateDropdown((prev) => !prev);
    };

    const handleCreateChange = async (e: any) => {
        createDCOButtonClicked.current = true;
        createDocumentButtonClicked.current = false;
        if (window.location.pathname.includes('create-document')) {
            setBtnCLicked(false);
            setLeaveModal(true);
        } else {
            e.stopPropagation();
            setCreateChangeBtnLoading(true);

            const queryParams = {
                inputStr: 'DCO',
                sortBy: 'asc',
            };
            let dcoDocTypeId = -1;
            const res = await getDocTypes(queryParams);
            if (res?.apiStatus === 'SUCCESS') {
                dcoDocTypeId = res?.docTypeCard[0]?.id;
            } else {
                toast.error('Something went wrong while fetching DCO');
            }

            const createDocRes = await createDocument(dcoDocTypeId, {});
            if (createDocRes?.apiStatus === 'SUCCESS') {
                navigate(`/create-document?doc_id=${createDocRes?.docId}`);
            } else if (createDocRes?.apiStatus === 'FAILURE') {
                toast.error(createDocRes.errorMessage);
            }
            setCreateChangeBtnLoading(false);
            setShowCreateDropdown(false);
        }
    };

    const handleContracts = async () => {
        navigate(`/clm/dashboard`);
    };

    const handleDashboardClick = (e: any) => {
        e.stopPropagation();
        setShowCreateDropdown((prev) => !prev);
    };

    const handleReports = () => {
        navigate(`/reportList`);
    };

    const handleCreateDocumentButtonClick = () => {
        createDocumentButtonClicked.current = true;
        createDCOButtonClicked.current = false;
        if (window.location.pathname.includes('create-document')) {
            setLeaveModal(true);
            setBtnCLicked(false);
        } else {
            setShowDocCreateModal(true);
        }
    };

    const onConfirm = async () => {
        if (createDocumentButtonClicked.current) {
            setShowDocCreateModal(true);
        } else if (createDCOButtonClicked.current) {
            setLeaveBtnLoading(true);
            const queryParams = {
                inputStr: 'DCO',
                sortBy: 'asc',
            };
            let dcoDocTypeId = -1;
            let errorOccurred = false;

            try {
                const res = await getDocTypes(queryParams);
                if (res?.apiStatus === 'SUCCESS') {
                    dcoDocTypeId = res?.docTypeCard[0]?.id;
                } else {
                    toast.error('Something went wrong while fetching DCO');
                    errorOccurred = true;
                }
                if (!errorOccurred) {
                    const createDocRes = await createDocument(dcoDocTypeId, {});
                    if (createDocRes?.apiStatus === 'SUCCESS') {
                        navigate(`/create-document?doc_id=${createDocRes?.docId}`);
                    } else if (createDocRes?.apiStatus === 'FAILURE') {
                        toast.error(createDocRes.errorMessage);
                    }
                }
            } catch (error) {
                console.error('API Call Failed:', error);
                toast.error('An error occurred while processing your request');
            } finally {
                setLeaveBtnLoading(false);
                setShowCreateDropdown(false);
            }
        }
        createDocumentButtonClicked.current = false;
        createDCOButtonClicked.current = false;
    };

    return (
        <header className="header">
            <div className="header__top-bar">
                <Logo />
                {user.id && user.id !== -1 && !isSmallScreen && (
                    <SearchBar setShowSearch={setShowSearch} />
                )}
                <div className="header__content">
                    {user.id && user.id !== -1 && (
                        <>
                            {stepsEnabled && (
                                <InitialFeaturesTour
                                    stepsEnabled={stepsEnabled}
                                    setStepsEnabled={setStepsEnabled}
                                    initialStep={initialStep}
                                />
                            )}
                            {!showSearch && (
                                <div className="search-buttons">
                                    <button
                                        type="button"
                                        className={`btn primary-btn create-plus-btn ${showCreateDropdown ? 'show' : ''
                                            }`}
                                        onClick={handleCreateClick}
                                        onMouseEnter={() => setShowCreateDropdown(true)}
                                        onMouseLeave={() => setShowCreateDropdown(false)}
                                    >
                                        {HEADER.BUTTON.CREATE} <PlusIconSvg />
                                    </button>
                                    {/* {user.isAdmin && (
                                        <button
                                            type="button"
                                            className="btn-doctypes"
                                            onClick={handleDocumentType}
                                            title="Create new Document Type"
                                        >
                                            <DocumentTypesIconSvg />
                                        </button>
                                    )} */}

                                    {/* {showTourButton && (
                                        <button
                                            type="button"
                                            className="btn-retake-icon"
                                            onClick={() => setStepsEnabled(true)}
                                        >
                                            Retake Tour
                                        </button>
                                    )}
                                    <div
                                        className="search-icon"
                                        onClick={() => setShowSearch(true)}
                                        role="presentation"
                                    >
                                        <SearchIconSvg />
                                    </div> */}
                                    {true && (
                                        <div
                                            className={`search-buttons__dropdown ${showCreateDropdown ? 'show' : ''
                                                }`}
                                            onMouseEnter={() => setShowCreateDropdown(true)}
                                            onMouseLeave={() => setShowCreateDropdown(false)}
                                        >
                                            <button
                                                type="button"
                                                className="btn-icon"
                                                onClick={handleCreateDocumentButtonClick}
                                                title="Create Document"
                                                disabled={createChangeBtnLoading}
                                            >
                                                {STATIC_CONTENT.HEADER.BUTTON.CREATE_DOC}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn-icon"
                                                onClick={handleCreateChange}
                                                title="Create Change"
                                                disabled={createChangeBtnLoading}
                                            >
                                                {STATIC_CONTENT.HEADER.BUTTON.CREATE_CHANGE}
                                                {createChangeBtnLoading && (
                                                    <Oval
                                                        color="#F37321"
                                                        height={14}
                                                        width={14}
                                                    />
                                                )}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn-icon"
                                                onClick={handleReports}
                                                title="Reports"
                                                disabled={createChangeBtnLoading}
                                            >
                                                {STATIC_CONTENT.HEADER.BUTTON.REPORTS}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                            {(user.isAdmin || user.isClmAdmin || user.isDcoAdmin || user.isTrapAdmin) && (
                                <div className="dashboard-buttons">
                                    <button
                                        type="button"
                                        className={`btn primary-btn dashboard ${showDashboardDropdown ? 'show' : ''
                                            }`}
                                        onClick={handleDashboardClick}
                                        onMouseEnter={() => setShowDashboardDropdown(true)}
                                        onMouseLeave={() => setShowDashboardDropdown(false)}
                                    >
                                        <DocumentTypesIconSvg />
                                    </button>
                                    {true && (
                                        <div
                                            className={`dashboard-buttons__dropdown ${showDashboardDropdown ? 'show' : ''
                                                }`}
                                            onMouseEnter={() => setShowDashboardDropdown(true)}
                                            onMouseLeave={() => setShowDashboardDropdown(false)}
                                        >
                                            {(user.isAdmin || user.isDcoAdmin || user.isTrapAdmin) && (
                                                <button
                                                    type="button"
                                                    className="btn-icon"
                                                    onClick={handleDocumentType}
                                                    title="Admin Dashboard"
                                                >
                                                    Admin Dashboard
                                                </button>
                                            )}
                                            {(user.isAdmin || user.isClmAdmin) && (
                                                <button
                                                    type="button"
                                                    className="btn-icon"
                                                    onClick={handleContracts}
                                                    title="Contracts Dashboard"
                                                >
                                                    CLM Dashboard
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                    <UserHeader />
                    <div
                        role="presentation"
                        className="user-guide"
                        onClick={() => navigate(`/documentation`)}
                        onKeyPress={() => navigate('/documentation')}
                    >
                        <QuestionCircle style={{ color: '#000000', fontSize: '24px' }} />
                    </div>
                </div>
            </div>
            {user.id && user.id !== -1 && isSmallScreen && (
                <div className="header__search-bar">
                    <SearchBar setShowSearch={setShowSearch} />
                </div>
            )}
            {/* <div className="header__mobile-doc-create-btn">
                <button
                    type="button"
                    className="btn-icon"
                    onClick={() => setShowDocCreateModal(true)}
                >
                    <EditSvg />
                </button>
            </div> */}
            {showDocCreateModal && (
                <DocCreateModal
                    isModalOpen={showDocCreateModal}
                    onModalClose={setShowDocCreateModal}
                    selectedDocType={selectedDocType}
                    setSelectedDocType={setSelectedDocType}
                    docTypesList={docTypesList}
                    setDocTypesList={setDocTypesList}
                />
            )}
            {showLeaveModal && (
                <Modal
                    isOpen={showLeaveModal}
                    onRequestClose={() => setLeaveModal(false)}
                    contentLabel="confirmation modal"
                    className="confirmation__modal"
                >
                    <PlusIconSvg className="close-btn" onClick={() => setLeaveModal(false)} />
                    <div className="heading">
                        <h1>Do you want to leave before Saving</h1>
                        <h4>All changes would be lost</h4>
                    </div>
                    <div className="buttons">
                        <button type="button" onClick={() => setLeaveModal(false)}>
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="primary-btn"
                            disabled={btnClicked}
                            style={{ display: 'flex', justifyContent: 'center' }}
                            onClick={async () => {
                                setBtnCLicked(true);
                                await onConfirm();
                                setLeaveModal(false);
                            }}
                        >
                            Leave
                            {leaveBtnLoading && (
                                <span style={{ marginLeft: '5px' }}>
                                    <Oval color="#F37321" height={14} width={14} />
                                </span>
                            )}
                        </button>
                    </div>
                </Modal>
            )}
        </header>
    );
};

export default Header;
