import axios from 'axios';
import { getFromInMemory } from '../utils/in-memory-storage';
import { IAPIResponseStatus } from '../interfaces';
import { IAttachment, IAttachmentMeta } from '../interfaces/attachment';
import {
    getRequest,
    deleteRequest,
    getRequestWithResponse,
    getAPIStatus,
    putRequest,
} from '../utils/api-request';

// eslint-disable-next-line max-lines-per-function
const createAttachment = async (
    documentId: number,
    documentVersionId: number,
    file: File,
    metaData: IAttachmentMeta,
): Promise<{ apiStatus: IAPIResponseStatus, id?: number } | undefined> => {
    try {
        const metaString = {
            attachmentType: metaData.attachmentType,
            displayName: metaData.displayName,
            extension: metaData.extension,
            mimeType: metaData.mimeType,
            name: metaData.name,
            size: metaData.size.toString(),
        };
        const data = new FormData();
        data.append('file', file);
        data.append('documentId', documentId.toString());
        data.append('documentVersionId', documentVersionId.toString());
        data.append('metaData', JSON.stringify(metaString));
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: ` Bearer ${getFromInMemory()}`,
        };
        const res = await axios.post(
            `${process.env.REACT_APP_API_HOST}/api/v1/documents/attachment/`,
            data,
            {
                headers,
                withCredentials: true,

            },
        );
        if (res) {
            const status = getAPIStatus(res.status);
            return { apiStatus: status, id: res?.data?.id };
        }

        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getAttachments = async (
    documentVersionId: number,
    allowReviseByAnyone: boolean,
): Promise<{ apiStatus: IAPIResponseStatus; attachments: IAttachment[] } | undefined> => {
    try {
        const res = await getRequest(
            `documents/attachment/list?documentVersionId=${documentVersionId}&allowReviseByAnyone=${allowReviseByAnyone}`,
        );
        if (res?.apiStatus === 'SUCCESS') {
            const attachments: IAttachment[] = [];

            const attachmentsData = res.response;
            // eslint-disable-next-line no-restricted-syntax
            for (const attachment of attachmentsData) {
                const fileMeta: IAttachmentMeta = {
                    name: attachment.metaData.name,
                    size: attachment.metaData.size,
                    mimeType: attachment.metaData.mimeType,
                    extension: attachment.metaData.extension,
                    attachmentType: attachment.metaData.attachmentType,
                    displayName: attachment.metaData.displayName,
                };
                attachments.push({
                    id: attachment.id,
                    description: attachment.description,
                    fileMeta,
                    createdAt: attachment.createdAt
                });
            }
            return { apiStatus: 'SUCCESS', attachments };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const deleteAttachment = async (attachmentId: number): Promise<IAPIResponseStatus | undefined> => {
    try {
        const res = await deleteRequest(`documents/attachment/?attachmentId=${attachmentId}`);
        if (res?.apiStatus === 'SUCCESS') {
            return 'SUCCESS';
        }

        if (res?.apiStatus === 'FAILURE') {
            return 'FAILURE';
        }

        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const updateAttachment = async (
    id: number,
    description?: string,
): Promise<IAPIResponseStatus | undefined> => {
    const body: any = {};
    if (description) {
        body.description = description;
    }
    try {
        const res = await putRequest(`documents/attachment/?attachmentId=${id}`, body);
        if (res?.apiStatus === 'SUCCESS') {
            return 'SUCCESS';
        }
        if (res?.apiStatus === 'FAILURE') {
            return 'FAILURE';
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const downloadAttachment = async (attachmentId: number, fileName: string) => {
    try {
        const res = await getRequestWithResponse(`documents/attachment/?id=${attachmentId}`, {
            responseType: 'blob',
        });
        if (res?.apiStatus === 'SUCCESS') {
            if (typeof res.response === 'object' && res.response instanceof Blob) {
                const blob = res.response;
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.setAttribute('download', fileName || '');
                document.body.appendChild(link);
                link.click();
                return res.apiStatus;
            }
            // Handle the case where the response is not a Blob
            console.error('Unexpected response type:', typeof res.response);
            return 'FAILURE';
        }
        if (res?.apiStatus === 'FAILURE') {
            return res.apiStatus;
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

export { createAttachment, getAttachments, deleteAttachment, downloadAttachment, updateAttachment };
